import React from 'react';
import './comments.scss';
 
interface CommentsProps {
  isOpen: boolean;
  onClose: () => void;
  content: any;
}
 
const Comments: React.FC<CommentsProps> = ({ isOpen, onClose, content }) => {
    if (!isOpen) {
        return null;
    }
    const SortContent = content.reverse();
    return (
        <div className='commentss-overlayy'>
            <div className='commentss-contents'>
                <div className='comment-close-buttons' onClick={onClose}>
                    &times;
                </div>
                {SortContent.map((item: any, index: number) => (
                    <div key={index} className="comment">
                        <div className="comment-header" >
                        <div className="comment-name">{item.created_by}</div> 
                         commented on
                        <div className="comment-date">  {item.created_date}</div>
                        </div>
                        <div className="comment-text">{item.comment}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
 
export default Comments;