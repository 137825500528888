import { useEffect } from "react";
import Loading from "../../layout/loader/Loading";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../httpInterceptor";

const Home = () => {
  const navigate = useNavigate();
  let safeData: string;
  useEffect(() => {
    if (
      !sessionStorage.getItem("PBT_ACCESS_TOKEN") &&
      !window.location.href.includes("code")
    ) {
      window.location.assign(`${process.env.REACT_APP_COGNITOSSO_URL}`);
    } else if (window.location.href.includes("code")) {
      sessionStorage.setItem("code", window.location.href.split("=")[1]);

      const code = sessionStorage.getItem("code");

      fetch(
        `https://ltim-de-user-pool.auth.us-east-1.amazoncognito.com/oauth2/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `grant_type=authorization_code&code=${code}&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`,
        }
      )
        .then((response) => response.json())
        .then((res) => {
          const { access_token, id_token, refresh_token } = res;
          var decode: any = jwtDecode(id_token);
          sessionStorage.setItem("PBT_ACCESS_TOKEN", access_token);
          sessionStorage.setItem("PBT_REFRESH_TOKEN", refresh_token);
          sessionStorage.setItem("PBT_ID_TOKEN", id_token);
          sessionStorage.setItem("PBT_FIRSTNAME", decode.given_name);
          sessionStorage.setItem("PBT_LASTNAME", decode.family_name);
          sessionStorage.setItem("PBT_EMAIL", decode.email);

          axiosInstance
            .get(
              `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/getUserRole/${decode.email}`
            )
            .then((res) => {
              if (res.data.message) {
                safeData = btoa("NA");
              }
              if (!res.data.message) {
                safeData = btoa(res.data);
              }
              sessionStorage.setItem("key", safeData);
            });
          // setTimeout(() => {
          //   navigate("/dashboard");
          // }, 4000);

          const redirectInterval = setInterval(() => {
            if (sessionStorage.getItem("key")) {
              navigate("/dashboard");
              clearInterval(redirectInterval);
            }
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/dashboard");
    }
  }, []);
  return <Loading />;
};

export default Home;
