import React, { useEffect, useState } from "react";
import "./newAssessmentForm.scss";
import Question from "./questions/Question";
import History from "./history/History";
import Findings from "./findings/Findings";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { convertToIso, db } from "../../../../database";
import { updateNewAssessmentVariable } from "../../../../redux/slices/globalVariableSlice";
import { toast } from "react-toastify";
import ProjectDetailsBar from "../project-details-bar/ProjectDetailsBar";
import axiosInstance from "../../../../httpInterceptor";
import {
  AssessmentData,
  ProjectDataType,
  Qnadetails,
} from "../../../../models/interfaces/interfaces";
import Loading from "../../../layout/loader/Loading";

const NewAssessmentForm: React.FC = () => {
  const { proj_id } = useParams<{
    proj_id: string;
  }>();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  let newAssessmentVar = JSON.parse(
    sessionStorage.getItem("newAssessment") || ""
  );

  // state for changing the tabs between question,history and findings
  const [activeTab, setActiveTab] = useState("questions");
  const [isReAssessClicked, setIsReAssessClicked] = useState(false);
  const [reviewer, setReviewerData] = useState<any>(null);
  const [reviewerNames, setReviewerNames] = useState<any>(null);
  const [isAssessForThisProjId, setIsAssessForThisProjId] =
    useState<boolean>(true);

  //state to handle accordion like to show or hide the project form
  const [isopen, setIsOpen] = useState(false);

  const [projectHealthData, setProjectHealthData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  // const [isBlur, setIsBlur] = useState(false);
  const [setname, setName] = useState("");

  useEffect(() => {
    newAssessmentVar = JSON.parse(
      sessionStorage.getItem("newAssessment") || ""
    );
  }, [isReAssessClicked]);

  const updateQnaDetails = (response: Qnadetails, len: number) => {
    if (Object.keys(response).length === len) {
      toast.success("data saved");
    }
    setAssessmentData((prevState: any) => {
      const updatedDetails = prevState.assessmentdetails.map((detail: any) => ({
        ...detail,
        qnadetails: response,
      }));
      return { ...prevState, assessmentdetails: updatedDetails };
    });
  };

  //state for setting the new Assessment Data
  const [projectData, setProjectData] = useState<ProjectDataType>({
    proj_id: "",
    proj_name: "",
    type: "",
    pmid: "",
    pmname: "",
    du: "",
    pu: "",
    account: "",
    proj_start_date: "",
    proj_end_date: "",
    proj_desc: "",
    reviewer: "",
    status: "",
    set_name: "",
  });

  const [assessmentData, setAssessmentData] = useState<AssessmentData>({
    projectid: proj_id,
    assessmentdetails: [
      {
        assessmentdate: `${new Date().toISOString().split("T")[0]}`,
        projectstatus: "",
        reasonforchange: "",
        qnadetails: [],
        created_by: `${sessionStorage.getItem(
          "PBT_FIRSTNAME"
        )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
        modified_by: "",
      },
    ],
  });

  const fetchUserDataForReviewer = async (query: string) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`
      );

      const result = res.data.filter((item: any) => {
        if (!query) {
          setReviewerData(null);
        }
        if (item.role === "Reviewer" || item.role === "Admin") {
          const searchText = query.toLowerCase();
          const fullName = item.name.toLowerCase();

          return query && fullName.startsWith(searchText);
        }
      });
      setReviewerData(result);
      const names = result.map((item: any) => {
        const { name, ...rest } = item;
        return name;
      });
      setReviewerNames(names);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
    if (name === "reviewer") fetchUserDataForReviewer(value);
    console.log(e);
    if (e.target.name === "proj_desc" || e.target.name === "reviewer") {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  // const handleOnBlur = () => {
  //   setIsBlur(true);
  // };

  const handleAssesmentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setAssessmentData((prevState) => ({
      ...prevState,
      assessmentdetails: prevState.assessmentdetails.map((detail, i) => ({
        ...detail,
        [name]: value,
      })),
    }));
  };

  useEffect(() => {
    const fetProjectData = async () => {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
      );

      const localData = res.data.filter(
        (item: { projectid: string }) => item.projectid === proj_id
      );

      // const { set_name } = localData[0];

      setName(localData[0].set_name);
    };

    fetProjectData();
  }, [proj_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_COMPASS_BOOMI_URL}?Projectid=${proj_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": `${process.env.REACT_APP_COMPASS_X_API_KEY}`,
            },
          }
        );

        //data coming from compass2.0 api
        const compassData = data.result[0];

        //data coming from local db
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
        );

        //taking out particular project id data so that we can merge
        const localData = res.data.filter(
          (item: { projectid: string }) => item.projectid === proj_id
        );

        const {
          projectNumber,
          projectName,
          u_project_category,
          primaryPM_psno,
          primaryPM,
          u_delivery_unit,
          u_practice_unit,
          accountName,
          start_date,
          end_date,
        } = compassData;

        const { proj_desc, reviewer, status, set_name } = localData[0];

        //setting the data in the state
        setProjectData({
          proj_id: projectNumber,
          proj_name: projectName,
          type: u_project_category,
          pmid: primaryPM_psno,
          pmname: primaryPM,
          du: u_delivery_unit,
          pu: u_practice_unit,
          account: accountName,
          proj_start_date: start_date,
          proj_end_date: end_date,
          proj_desc,
          reviewer,
          status,
          set_name,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    const fetchProjectHealthData = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${proj_id}`
        );

        if (data.length === 0) {
          dispatch(updateNewAssessmentVariable(true));
          setIsAssessForThisProjId(false);
        }

        const len = data[0].assessmentdetails.length;

        const qnadetailsWithQuestions = await Promise.all(
          data[0].assessmentdetails[len - 1].qnadetails.map(
            async (item: {
              questionid: string;
              response: string;
              remarks: string;
            }) => {
              const question = await axiosInstance.get(
                `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${item.questionid}`
              );
              return {
                questionid: item.questionid,
                description: question.data.description,
                type: question.data.type,
                response: item.response,
                remarks: item.remarks,
              };
            }
          )
        );

        const {
          assessmentdate,
          projectstatus,
          reasonforchange,
          created_by,
          modified_by,
          created_date,
          modified_date,
        } = data[0].assessmentdetails[len - 1];

        const latestAssessment = {
          proj_id,
          assessmentdetails: [
            {
              assessmentdate,
              projectstatus,
              reasonforchange,
              created_by,
              created_date,
              modified_by,
              modified_date,
              qnadetails: qnadetailsWithQuestions,
            },
          ],
        };
        setProjectHealthData([latestAssessment]);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    if (newAssessmentVar === false) {
      fetchProjectHealthData();
    }
  }, [proj_id]);

  const handleNewAssessmentFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const isResponseSaved = assessmentData.assessmentdetails.map((detail) => {
      return detail.qnadetails.length > 0;
    });

    if (isResponseSaved.every((saved) => saved)) {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/create-projectassesment`,
          assessmentData
        );
        if (response.data) {
          toast.success("Assessment Completed successfully");
          setIsEnabled(false);
        }
        setTimeout(() => {
          navigate("/dashboard");
        }, 2400);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("You have some unsaved changes, please save to continue");
    }
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);

    if (tabName === "questions") {
      setAssessmentData((prevState) => ({
        ...prevState,
        assessmentdetails: prevState.assessmentdetails.map((detail) => ({
          ...detail,
          projectstatus: "",
          reasonforchange: "",
          assessmentdate: `${new Date().toISOString().split("T")[0]}`,
        })),
      }));
    }
  };

  const handleOpenClose = () => {
    setIsOpen(!isopen);
  };
  const handleEditSubmit = async () => {
    const { proj_desc, reviewer } = projectData;
    if (!reviewerNames.includes(projectData.reviewer)) {
      toast.error("Please select reviewer from suggestions!");
      return;
    }
    try {
      const res = await axiosInstance.patch(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/update/${proj_id}`,
        { proj_desc, reviewer }
      );
      if (isEnabled) {
        toast.success("Project Data is updated successfully");
        setIsEnabled(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReviewerOnClick = (value: string) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      reviewer: value,
    }));
    setReviewerData(null);
  };

  const handleReviewerBlur = () => {
    setTimeout(() => {
      setReviewerData(null);
    }, 150);
  };

  const showDetails =
    projectData.proj_id !== "" && projectData.proj_name !== "";

  // const [isModalOpen, setIsModalOpen] = useState(true);
  // const [setValue, setSetValue] = useState('');

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // const handleSelectValue = (value: React.SetStateAction<string>) => {
  //   setSetValue(value);
  // };

  return (
    <>
      <form onSubmit={handleNewAssessmentFormSubmit}>
        <ProjectDetailsBar
          projectid={projectData.proj_id}
          projectName={projectData.proj_name}
          showDetails={showDetails}
          handleProjectDetailsBar={handleOpenClose}
        />
        {isopen && (
          <div className="newAssessmentForm">
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>Project Id</label>
                <input
                  type="text"
                  name="proj_id"
                  autoComplete="off"
                  readOnly
                  required
                  disabled
                  value={projectData.proj_id}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Project Name</label>
                <input
                  type="text"
                  name="proj_name"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.proj_name}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Type</label>
                <input
                  type="text"
                  name="type"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.type}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>PMID</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="pmid"
                  required
                  readOnly
                  disabled
                  value={projectData.pmid}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>PMNAME</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="pmname"
                  required
                  readOnly
                  disabled
                  value={projectData.pmname}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Account</label>
                <input
                  type="text"
                  name="account"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.account}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>DU</label>
                <input
                  type="text"
                  name="du"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.du}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>PU</label>
                <input
                  type="text"
                  name="pu"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.pu}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Project Start Date</label>
                <input
                  type="text"
                  name="proj_start_date"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={convertToIso(projectData.proj_start_date)}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>Project End Date</label>
                <input
                  type="text"
                  value={convertToIso(projectData.proj_end_date)}
                  name="proj_end_date"
                  readOnly
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Project Description</label>
                <textarea
                  name="proj_desc"
                  value={projectData.proj_desc}
                  autoComplete="off"
                  required
                  onChange={handleChange}
                  // onBlur={handleOnBlur}
                />
              </div>
              <div className="row projectIdInputBoxWithSuggestions">
                <label>Reviewer</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="reviewer"
                  onFocus={(e) => fetchUserDataForReviewer(e.target.value)}
                  onBlur={handleReviewerBlur}
                  value={projectData.reviewer}
                  onChange={handleChange}
                />
                <ul
                  className={reviewer?.length > 0 ? "showReviewerResults" : ""}
                >
                  {reviewer?.length > 0 &&
                    reviewer.map((result: any, idx: number) => {
                      return (
                        <li
                          className="resultItems"
                          key={idx}
                          onClick={() => handleReviewerOnClick(result.name)}
                        >
                          <span> {result.name}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <section className="archiveProject">
              <div>
                <input
                  className="archiveCheckbox"
                  type="checkbox"
                  name="status"
                  value="closed"
                  checked={projectData.status === "closed" ? true : false}
                  onChange={handleChange}
                  id="status"
                />
                <label htmlFor="status">Archive</label>
                <div className="handleEditButton">
                  {isEnabled && (
                    <h2 className="buttonEdit" onClick={handleEditSubmit}>
                      Submit
                    </h2>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}

        {/* section for questions history and findings */}
        <section className="newAssessmentTabSection">
          {newAssessmentVar === "true" ? (
            <div className="tabsContainer">
              <p
                style={
                  activeTab === "questions"
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        borderTopLeftRadius: "10px",
                      }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("questions")}
              >
                Questions
              </p>
              <p
                style={
                  activeTab === "findings"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("findings")}
              >
                Findings
              </p>
            </div>
          ) : (
            <div className="tabsContainer">
              <p
                style={
                  activeTab === "questions"
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        borderTopLeftRadius: "10px",
                      }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("questions")}
              >
                Questions
              </p>
              <p
                style={
                  activeTab === "history"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("history")}
              >
                History
              </p>
              <p
                style={
                  activeTab === "findings"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("findings")}
              >
                Findings
              </p>
              {/* reAssessment btn is here */}
              {isAssessForThisProjId && activeTab === "questions" && (
                <div className="reAssessBtn">
                  {newAssessmentVar === false ? (
                    <input
                      type="button"
                      onClick={() => {
                        setIsReAssessClicked(true);
                        dispatch(updateNewAssessmentVariable(true));
                        // setAssessmentData((prevState) => ({
                        //   ...prevState,
                        //   assessmentdetails: prevState.assessmentdetails.map(
                        //     (detail) => ({
                        //       ...detail,
                        //       projectstatus: "",
                        //       reasonforchange: "",
                        //       assessmentdate: `${
                        //         new Date().toISOString().split("T")[0]
                        //       }`,
                        //     })
                        //   ),
                        // }));
                      }}
                      value={"Re-Assess"}
                    />
                  ) : (
                    <input
                      type="button"
                      onClick={() => {
                        setIsReAssessClicked(false);
                        dispatch(updateNewAssessmentVariable(false));
                        // setAssessmentData((prevState) => ({
                        //   ...prevState,
                        //   assessmentdetails: prevState.assessmentdetails.map(
                        //     (detail) => ({
                        //       ...detail,
                        //       projectstatus: "",
                        //       reasonforchange: "",
                        //       assessmentdate: `${
                        //         new Date().toISOString().split("T")[0]
                        //       }`,
                        //     })
                        //   ),
                        // }));
                      }}
                      value={"Cancel"}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            {newAssessmentVar === "true" ? (
              projectData.set_name !== "" ? (
                (activeTab === "questions" && (
                  <Question
                    projectid={proj_id}
                    setname={setname}
                    onData={updateQnaDetails}
                  />
                )) ||
                (activeTab === "findings" && <Findings />)
              ) : (
                <Loading />
              )
            ) : projectData.set_name !== "" ? (
              (activeTab === "questions" && (
                <Question
                  projectid={proj_id}
                  setname={setname}
                  onData={updateQnaDetails}
                />
              )) ||
              (activeTab === "history" && <History projectid={proj_id} />) ||
              (activeTab === "findings" && <Findings />)
            ) : (
              <Loading />
            )}
          </div>
        </section>
        {/* section for the project health */}
        {activeTab === "questions" && (
          <section className="projectHealthSectionMain">
            <div>
              <div className="projectHealthSection">
                <p>Project Health</p>
                <div className="projectHealthInputContainer">
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Project Status</label>
                      <select
                        name="projectstatus"
                        onChange={handleAssesmentChange}
                        disabled={projectHealthData.length > 0}
                        value={
                          projectHealthData[0].assessmentdetails[0]
                            .projectstatus
                        }
                      >
                        <option defaultValue="Select Project Status">
                          Select Project Status
                        </option>
                        <option value="red">Red</option>
                        <option value="amber">Amber</option>
                        <option value="green">Green</option>
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label>Project Status</label>
                      <select
                        name="projectstatus"
                        onChange={handleAssesmentChange}
                        value={
                          assessmentData.assessmentdetails[0].projectstatus
                        }
                      >
                        <option defaultValue="Select Project Status">
                          Select Project Status
                        </option>
                        <option value="red">Red</option>
                        <option value="amber">Amber</option>
                        <option value="green">Green</option>
                      </select>
                    </div>
                  )}
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Reason for change</label>
                      <input
                        type="text"
                        name="reasonforchange"
                        onChange={handleAssesmentChange}
                        readOnly
                        value={
                          projectHealthData[0].assessmentdetails[0]
                            .reasonforchange
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Reason for change</label>
                      <input
                        type="text"
                        name="reasonforchange"
                        onChange={handleAssesmentChange}
                        value={
                          assessmentData.assessmentdetails[0].reasonforchange
                        }
                      />
                    </div>
                  )}
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Date</label>
                      <input
                        type="date"
                        name="assessmentdate"
                        readOnly
                        value={
                          projectHealthData[0].assessmentdetails[0].assessmentdate.split(
                            "T"
                          )[0]
                        }
                        onChange={handleAssesmentChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Date</label>
                      <input
                        type="date"
                        name="assessmentdate"
                        readOnly
                        value={
                          assessmentData.assessmentdetails[0].assessmentdate
                        }
                        onChange={handleAssesmentChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="newAssessmentFormBtn">
              <button disabled={!newAssessmentVar} type="submit">
                Submit
              </button>
            </div>
          </section>
        )}
      </form>
    </>
  );
};

export default NewAssessmentForm;
