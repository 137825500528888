import React, { useEffect, useState } from "react";
import Navbar from "../../layout/header/Header";
import "./leadershipReport.scss";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axiosInstance from "../../../httpInterceptor";
import {
  AssessmentData,
  ProjectDataType,
} from "../../../models/interfaces/interfaces";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const LeadershipReport = () => {
  const [selectedDUValue, setSelectedDUValue] = useState("All");
  const [selectedPUValue, setSelectPUValue] = useState("DL");
  const [flag, setFlag] = useState(false);
  const [duOptions, setDUoptions] = useState<string[]>([]);
  const [puOptions, setPUoptions] = useState<string[]>([]);
  const [accountOptions, setAccountoptions] = useState<string[]>([]);
  const [filterFlag, setFilterType] = useState<string>("All");
  const [projectListData, setProjectListData] = useState<ProjectDataType[]>([]);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [arrayAmber, setArrayAmber] = useState<number[]>([]);
  const [arrayRed, setArrayRed] = useState<number[]>([]);
  const [arrayGreen, setArrayGreen] = useState<number[]>([]);
  const [observationsArray, setObservationArray] = useState<number[]>([]);
  const [openStatusDataset, setOpenStatusArray] = useState<number[]>([]);
  const [inprogressDataset, setInprogressArray] = useState<number[]>([]);
  const [closedStatusDataset, setClosedStatusArray] = useState<number[]>([]);
  const [completedProjectCount, setCompletedProject] = useState<number[]>([]);
  const [yearArray, setYears] = useState<number[]>([]);
  const [isDisabled, setDisabled] = useState(false);
  const [observationData, setObservationData] = useState<any>([]);

  const [quarter1AmberCount, setquarter1AmberCount] = useState(0);
  const [quarter1RedCount, setquarter1RedCount] = useState(0);
  const [quarter1GreenCount, setquarter1GreenCount] = useState(0);

  const [quarter2AmberCount, setquarter2AmberCount] = useState(0);
  const [quarter2RedCount, setquarter2RedCount] = useState(0);
  const [quarter2GreenCount, setquarter2GreenCount] = useState(0);

  const [quarter3AmberCount, setquarter3AmberCount] = useState(0);
  const [quarter3RedCount, setquarter3RedCount] = useState(0);
  const [quarter3GreenCount, setquarter3GreenCount] = useState(0);

  const [quarter4AmberCount, setquarter4AmberCount] = useState(0);
  const [quarter4RedCount, setquarter4RedCount] = useState(0);
  const [quarter4GreenCount, setquarter4GreenCount] = useState(0);

  const [quarter1OpenCount, setQuarter1OpenCount] = useState(0);
  const [quarter1InprogressCount, setQuarter1InprogressCount] = useState(0);
  const [quarter1CloseCount, setQuarter1CloseCount] = useState(0);

  const [quarter2OpenCount, setQuarter2OpenCount] = useState(0);
  const [quarter2InprogressCount, setQuarter2InprogressCount] = useState(0);
  const [quarter2CloseCount, setQuarter2CloseCount] = useState(0);

  const [quarter3OpenCount, setQuarter3OpenCount] = useState(0);
  const [quarter3InprogressCount, setQuarter3InprogressCount] = useState(0);
  const [quarter3CloseCount, setQuarter3CloseCount] = useState(0);

  const [quarter4OpenCount, setQuarter4OpenCount] = useState(0);
  const [quarter4InprogressCount, setQuarter4InprogressCount] = useState(0);
  const [quarter4CloseCount, setQuarter4CloseCount] = useState(0);

  const MonthArray = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const QuarterArray = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];
  let ambercount = 0;
  let redcount = 0;
  let greencount = 0;
  const handleChange = (event: any) => {
    // setArrayAmber([]);
    // setArrayGreen([]);
    // setArrayRed([]);

    if (event.target.name === "account") {
      setSelectedValue(event.target.value);
      setFilterType(event.target.name);
    } else if (event.target.name === "DU") {
      setSelectedDUValue(event.target.value);
      setFilterType(event.target.name);
    } else if (event.target.name === "PU") {
      setSelectPUValue(event.target.value);
      setFilterType(event.target.name);
    } else if (event.target.name === "year") {
      setSelectedYear(event.target.value);
      setFilterType(event.target.name);
    } else if (event.target.name === "month") {
      setSelectedMonth(event.target.value);
      setFilterType(event.target.name);
    } else if (event.target.name === "quarter") {
      setSelectedQuarter(event.target.value);
      setFilterType(event.target.name);
      if (event.target.value !== "All") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    setFlag(true);
  };

  //duname dropdown options array
  const fetchProjectListData = async () => {
    let DUdata: string[] = [];
    let PUdata: string[] = [];
    let accountdata: string[] = [];
    let yearArray: number[] = [];
    //const res = db.projectListData;
    const projectList = await axiosInstance.get(
      `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
    );
    const lookupdata = await axiosInstance.get(
      `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/lookup`
    );
    setProjectListData(projectList.data);
    setFlag(true);
    //to push data in dropdown
    lookupdata.data.map((item: any, index: any) => {
      if (DUdata.indexOf(item.du) === -1) {
        DUdata.push(item.du);
      }
      item.accounts.map((acc: any) => {
        if (accountdata.indexOf(acc) === -1) {
          accountdata.push(acc);
        }
      });
    });
    PUdata.unshift("All");
    setDUoptions(DUdata);
    setPUoptions(PUdata);
    setAccountoptions(accountdata);

    //year dropdown array
    let currentDate = new Date();
    yearArray.push(currentDate.getFullYear());
    yearArray.push(currentDate.getFullYear() - 1);
    setYears([...yearArray]);
  };

  useEffect(() => {
    fetchProjectListData();
  }, []);

  //get project-status health from project-assessment-data
  const fetchAssessmentData = async () => {
    let dataOnProjectId;
    //map project-id with projectassesmentdata and get latest health
    // (async function getProjectHealthStatus() {
    dataOnProjectId = await Promise.all(
      projectListData.map(async (item: any) => {
        const projectId = item.projectid;
        // const assess = db.projectassessmentdata
        const assessmentdata = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectId}`
        );
        const returnedResult = assessmentdata.data
          .map((element: AssessmentData) => {
            if (element.projectid === projectId) {
              const length = element.assessmentdetails.length;
              return element.assessmentdetails[length - 1];
            }
          })
          .filter((item: AssessmentData) => {
            return item;
          });
        return Promise.all(returnedResult);
      })
    );

    if (filterFlag === "year") {
      dataOnProjectId.flat(1).map((item) => {
        let getyear = new Date(item.assessmentdate).getFullYear();
        if (getyear == selectedYear) {
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else if (filterFlag === "month") {
      dataOnProjectId.flat(1).map((item) => {
        let getmonth = new Date(item.assessmentdate).getMonth() + 1;
        if (getmonth == Number(selectedMonth)) {
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        } else if (selectedMonth === "All") {
          console.log(selectedMonth);
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else if (filterFlag === "quarter") {
      dataOnProjectId.flat(1).map((item) => {
        let getmonth = new Date(item.assessmentdate).getMonth() + 1;
        if (selectedQuarter === "Quarter 1") {
          if (getmonth === 4 || getmonth === 5 || getmonth === 6) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        } else if (selectedQuarter === "Quarter 2") {
          if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        } else if (selectedQuarter === "Quarter 3") {
          if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        } else if (selectedQuarter === "Quarter 4") {
          if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        } else if (selectedQuarter === "All") {
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else {
      // if (filterFlag === "All" || selectedMonth == "All") {
      dataOnProjectId.flat(1).map((item) => {
        return (
          item.projectstatus === "amber"
            ? ambercount++
            : item.projectstatus === "red"
            ? redcount++
            : item.projectstatus === "green"
            ? greencount++
            : redcount,
          greencount,
          ambercount
        );
      });
      // console.log(greencount, ambercount, redcount);
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    }
    // }
    // })();

    //Total No.of Findings and closed,open,inprogress count
    (async function getProjectFinding() {
      const findingsData = await axiosInstance.get(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/observations`
      );
      setObservationData(findingsData.data);
      //no.of findings
      let findingsCount = 0;
      let openStatusCount = 0;
      let inprogressCount = 0;
      let closeStatusCount = 0;
      if (filterFlag === "year") {
        findingsData.data.map((item: any) => {
          item.observations.map((observItem: any) => {
            let getyear = new Date(observItem.created_date).getFullYear();
            if (selectedYear == getyear) {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
            }
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      } else if (filterFlag === "month") {
        findingsData.data.map((item: any) => {
          item.observations.map((observItem: any) => {
            let getmonth = new Date(observItem.created_date).getMonth() + 1;
            if (getmonth == selectedMonth) {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
            } else if (selectedMonth === "All") {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
            }
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      } else if (filterFlag === "quarter") {
        findingsData.data.map((item: any) => {
          item.observations.map((observItem: any) => {
            let getmonth = new Date(observItem.created_date).getMonth() + 1;
            if (selectedQuarter === "Quarter 1") {
              if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            } else if (selectedQuarter === "Quarter 2") {
              if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            } else if (selectedQuarter === "Quarter 3") {
              if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            } else if (selectedQuarter === "Quarter 4") {
              if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            } else if (selectedQuarter === "All") {
              //if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
              // }
            }
            setObservationArray([findingsCount]);
            setOpenStatusArray([openStatusCount]);
            setInprogressArray([inprogressCount]);
            setClosedStatusArray([closeStatusCount]);
          });
        });
      } else {
        findingsData.data.map((item: any) => {
          item.observations.map((observItem: any) => {
            findingsCount++;
            observItem.status === "Open"
              ? openStatusCount++
              : observItem.status === "In Progress"
              ? inprogressCount++
              : observItem.status === "Close"
              ? closeStatusCount++
              : findingsCount++;
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      }
    })();

    //Total Number of projects completed.
    (async function getCompletedProjectCount() {
      let count = 0;
      let assessmentdata: any = [];
      let projectCount = 0;
      const assessmentdetails = await Promise.all(
        projectListData.map(async (item: any) => {
          if (item.status === "active") {
            const projectId = item.projectid;
            assessmentdata = await axiosInstance.get(
              `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectId}`
            );
          }
          if (assessmentdata.data?.length > 0) {
            count++;
            return assessmentdata.data[0].assessmentdetails;
          }
        })
      );
      setCompletedProject([count]);
      const data = assessmentdetails.filter((item) => item !== undefined);
      //console.log(data);

      data.flat(1).map((item: any) => {
        let getmonth = new Date(item.assessmentdate).getMonth() + 1;
        if (filterFlag === "year") {
          let getyear = new Date(item.assessmentdate).getFullYear();
          if (selectedYear == getyear) {
            projectCount++;
          }
          setCompletedProject([projectCount]);
        } else if (filterFlag === "month") {
          let getmonth = new Date(item.assessmentdate).getMonth() + 1;
          if (getmonth == selectedMonth) {
            projectCount++;
          } else if (selectedMonth === "All") {
            projectCount++;
          }
          setCompletedProject([projectCount]);
        } else if (filterFlag === "quarter") {
          if (selectedQuarter === "Quarter 1") {
            if (getmonth === 4 || getmonth === 5 || getmonth === 6) {
              projectCount++;
              setCompletedProject([projectCount]);
            }
          } else if (selectedQuarter === "Quarter 2") {
            if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
              projectCount++;
            } else {
              projectCount = 0;
            }
            setCompletedProject([projectCount]);
          } else if (selectedQuarter === "Quarter 3") {
            if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
              projectCount++;
            } else {
              projectCount = 0;
            }
            setCompletedProject([projectCount]);
          } else if (selectedQuarter === "Quarter 4") {
            if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
              projectCount++;
            } else {
              projectCount = 0;
            }
            setCompletedProject([projectCount]);
          } else if (selectedQuarter === "All") {
            projectCount++;
            setCompletedProject([projectCount]);
          }
        }
      });
    })();

    (async function getTableData() {
      let quarter1AmberCounts = 0;
      let quarter2AMbercounts = 0;
      let quarter3AmberCounts = 0;
      let quarter4AmberCounts = 0;
      let quarter1RedCounts = 0;
      let quarter2RedCounts = 0;
      let quarter3RedCounts = 0;
      let quarter4RedCounts = 0;
      let quarter1GreenCounts = 0;
      let quarter2GreenCounts = 0;
      let quarter3GreenCounts = 0;
      let quarter4GreenCounts = 0;

      // console.log(dataOnProjectId.flat(1));
      dataOnProjectId.flat(1).map((item: any) => {
        // const getyear = new Date(item.assessmentdate).getFullYear();
        const getMonth = new Date(item.assessmentdate).getMonth() + 1;
        //if (item.projectstatus === "amber") {
        if (
          (getMonth == 4 || getMonth == 5 || getMonth == 6) &&
          item.projectstatus === "amber"
        ) {
          quarter1AmberCounts++;
        } else if (
          (getMonth == 7 || getMonth == 8 || getMonth == 9) &&
          item.projectstatus === "amber"
        ) {
          quarter2AMbercounts++;
        } else if (
          (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
          item.projectstatus === "amber"
        ) {
          quarter3AmberCounts++;
        } else if (
          (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
          item.projectstatus === "amber"
        ) {
          quarter4AmberCounts++;
          //}
        } else if (
          (getMonth == 4 || getMonth == 5 || getMonth == 6) &&
          item.projectstatus === "red"
        ) {
          quarter1RedCounts++;
        } else if (
          (getMonth == 7 || getMonth == 8 || getMonth == 9) &&
          item.projectstatus === "red"
        ) {
          quarter2RedCounts++;
        } else if (
          (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
          item.projectstatus === "red"
        ) {
          quarter3RedCounts++;
        } else if (
          (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
          item.projectstatus === "red"
        ) {
          quarter4RedCounts++;
          // }
        } else if (
          (getMonth == 4 || getMonth == 5 || getMonth == 6) &&
          item.projectstatus == "green"
        ) {
          quarter1GreenCounts++;
        } else if (
          (getMonth == 7 || getMonth == 8 || getMonth == 9) &&
          item.projectstatus == "green"
        ) {
          quarter2GreenCounts++;
        } else if (
          (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
          item.projectstatus == "green"
        ) {
          quarter3GreenCounts++;
        } else if (
          (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
          item.projectstatus == "green"
        ) {
          quarter4GreenCounts++;
        }
        //}
      });
      // console.log(quarter1AmberCounts, quarter1GreenCounts, quarter1RedCounts);
      // console.log(quarter2AMbercounts, quarter2GreenCounts, quarter2RedCounts);
      // console.log(quarter3AmberCounts, quarter3GreenCounts, quarter3RedCounts);
      // console.log(quarter4AmberCounts, quarter4GreenCounts, quarter4RedCounts);

      setquarter1AmberCount(quarter1AmberCounts);
      setquarter1GreenCount(quarter1GreenCounts);
      setquarter1RedCount(quarter1RedCounts);

      setquarter2AmberCount(quarter2AMbercounts);
      setquarter2GreenCount(quarter2GreenCounts);
      setquarter2RedCount(quarter2RedCounts);

      setquarter3AmberCount(quarter3AmberCounts);
      setquarter3GreenCount(quarter3GreenCounts);
      setquarter3RedCount(quarter3RedCounts);

      setquarter4AmberCount(quarter4AmberCounts);
      setquarter4GreenCount(quarter4GreenCounts);
      setquarter4RedCount(quarter4RedCounts);

      let quarter1OpenCounts = 0;
      let quarter2OpenCounts = 0;
      let quarter3OpenCounts = 0;
      let quarter4OpenCounts = 0;
      let quarter1CloseCounts = 0;
      let quarter2CloseCounts = 0;
      let quarter3CloseCounts = 0;
      let quarter4CloseCounts = 0;
      let quarter1InprogressCounts = 0;
      let quarter2InprogressCounts = 0;
      let quarter3InprogressCounts = 0;
      let quarter4InprogressCounts = 0;

      observationData.map((item: any) => {
        // console.log(item);
        item.observations.map((element: any) => {
          const getmonth = new Date(element.created_date).getMonth() + 1;
          if (element.status === "Open") {
            if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
              quarter1OpenCounts++;
            } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
              quarter2OpenCounts++;
            } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
              quarter3OpenCounts++;
            } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
              quarter4OpenCounts++;
            }
          } else if (element.status === "Close") {
            if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
              quarter1CloseCounts++;
            } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
              quarter2CloseCounts++;
            } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
              quarter3CloseCounts++;
            } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
              quarter4CloseCounts++;
            }
          } else if (element.status === "In Progress") {
            if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
              quarter1InprogressCounts++;
            } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
              quarter2InprogressCounts++;
            } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
              quarter3InprogressCounts++;
            } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
              quarter4InprogressCounts++;
            }
          }
        });
      });
      setQuarter1OpenCount(quarter1OpenCounts);
      setQuarter1InprogressCount(quarter1InprogressCounts);
      setQuarter1CloseCount(quarter1CloseCounts);

      setQuarter2OpenCount(quarter2OpenCounts);
      setQuarter2InprogressCount(quarter2InprogressCounts);
      setQuarter2CloseCount(quarter2CloseCounts);

      setQuarter3OpenCount(quarter3OpenCounts);
      setQuarter3InprogressCount(quarter3InprogressCounts);
      setQuarter3CloseCount(quarter3CloseCounts);

      setQuarter4OpenCount(quarter4OpenCounts);
      setQuarter4InprogressCount(quarter4InprogressCounts);
      setQuarter4CloseCount(quarter4CloseCounts);
    })();
  };

  useEffect(() => {
    fetchAssessmentData();
  }, [selectedDUValue, selectedQuarter, flag, selectedYear, selectedMonth]);

  return (
    <div className="leadershipReportContainer">
      <Navbar />
      <div className="app-pageSize">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div className="app-pageHeader">Reports</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1, columnGap: "9%" }}>
                  <label className="shortlabel">Select the DU:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="DU"
                    onChange={handleChange}
                    // defaultValue={"All"}
                    disabled={true}
                  >
                    <option>All</option>
                    {duOptions.map((item: any, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1, columnGap: "30px" }}>
                  <label className="shortlabel">Select the PU:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="PU"
                    onChange={handleChange}
                    defaultValue={"DL"}
                    disabled={true}
                  >
                    <option value="DL" style={{ display: "none" }}>
                      DL
                    </option>
                    {/* {puOptions.map((item: any, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1 }}>
                  <label className="shortlabel">Select the Account:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="accountname"
                    onChange={handleChange}
                    disabled={true}
                  >
                    <option>All</option>
                    {accountOptions.map((item: any, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1 }}>
                  <label className="shortlabel">Select the Quarter:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="quarter"
                    onChange={handleChange}
                  >
                    <option value="All">All</option>
                    {QuarterArray.map((item: any, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1, columnGap: "2%" }}>
                  <label className="shortlabel">Select the Month:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="month"
                    disabled={isDisabled}
                    onChange={handleChange}
                  >
                    <option>All</option>
                    {MonthArray.map((item: any, index) => (
                      <option key={index} value={index + 1}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1, columnGap: "28px" }}>
                  <label className="shortlabel">Select the Year:</label>
                  <select
                    //className="selectbox"
                    className="options"
                    name="year"
                    onChange={handleChange}
                    disabled={isDisabled}
                  >
                    <option style={{ display: "none" }}>2024</option>
                    {yearArray.map((item: any, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            // justifyContent: "space-between",
            columnGap: "3%",
            marginBottom: "5%",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* Chart div for latest health*/}
            <div className="chartDiv">
              <div>
                <Bar
                  data={{
                    // Name of the variables on x-axis for each bar
                    labels: [""],
                    datasets: [
                      {
                        // Label for bars
                        label: "",
                        // Data or value of your each variable
                        data: arrayGreen,
                        // Color of each bar
                        backgroundColor: "#008000",
                        // Border color of each bar
                        borderColor: "#008000",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                      {
                        // Label for bars
                        label: "Amber",
                        // Data or value of your each variable
                        data: arrayAmber,
                        // Color of each bar
                        backgroundColor: "#FFA500",
                        // Border color of each bar
                        borderColor: "#FFA500",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                      {
                        // Label for bars
                        label: "Red",
                        // Data or value of your each variable
                        data: arrayRed,
                        // Color of each bar
                        backgroundColor: "#FF0000",
                        // Border color of each bar
                        borderColor: "#FF0000",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                    ],
                  }}
                  // Height of graph
                  height={240}
                  width={500}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                      datalabels: {
                        color: "white",
                        anchor: "end",
                        align: "end",
                      },
                    },

                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        display: false,
                        //beginAtZero:true
                      },
                      x: {
                        border: {
                          color: "#FFFFFF",
                        },
                        ticks: {
                          color: "#FFFFFF",
                        },
                      },
                      customGroups: {
                        axis: "x",
                        labels: ["Project Health Report"],
                        grid: {
                          drawOnChartArea: false,
                          //lineWidth: [1, 1, 0, 0, 1, 1],
                        },
                        ticks: {
                          //maxRotation: 90,
                          //minRotation: 90,
                          labelOffset: 20,
                          color: "white",
                          font: { size: 14 },
                        },
                      },
                    },

                    layout: {
                      padding: {
                        left: 40,
                        right: 80,
                        top: 30,
                        bottom: 10,
                      },
                    },
                  }}
                />
              </div>
              <ul
                style={{
                  padding: "0px",
                  margin: "0px",
                  color: "#ffffff",
                  position: "absolute",
                  left: "85%",
                }}
              >
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FF0000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Red
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FFA500",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Amber
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#008000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Green{" "}
                  </div>
                </div>
              </ul>
            </div>
          </div>
          {/* chart div for findings */}
          <div style={{ flex: 1 }}>
            <div className="chartDiv">
              <div>
                <Bar
                  data={{
                    labels: [""],
                    datasets: [
                      {
                        // Label for bars
                        label: "No.of Findings",
                        // Data or value of your each variable
                        data: observationsArray,
                        // Color of each bar
                        backgroundColor: "#FFA500",
                        // Border color of each bar
                        borderColor: "#FFA500",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "Closed status",
                        // Data or value of your each variable
                        data: closedStatusDataset,
                        // Color of each bar
                        backgroundColor: "pink",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "Open status",
                        // Data or value of your each variable
                        data: openStatusDataset,
                        // Color of each bar
                        backgroundColor: "violet",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "In-Progress status",
                        // Data or value of your each variable
                        data: inprogressDataset,
                        // Color of each bar
                        backgroundColor: "#008000",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                    ],
                  }}
                  // Height of graph
                  height={240}
                  width={500}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                      datalabels: {
                        color: "white",
                        anchor: "end",
                        align: "end",
                      },
                    },

                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        display: false,
                        //beginAtZero:true
                      },
                      x: {
                        border: {
                          color: "#FFFFFF",
                        },
                        ticks: {
                          color: "#FFFFFF",
                        },
                      },
                      customGroups: {
                        axis: "x",
                        labels: ["Number Of Findings Shared Report"],
                        grid: {
                          drawOnChartArea: false,
                        },
                        ticks: {
                          labelOffset: 20,
                          color: "white",
                          font: { size: 14 },
                        },
                      },
                    },
                    layout: {
                      padding: {
                        left: 40,
                        right: 90,
                        top: 30,
                        bottom: 10,
                      },
                    },
                  }}
                />
              </div>
              <ul
                style={{
                  padding: "0px",
                  margin: "0px",
                  color: "#ffffff",
                  position: "absolute",
                  left: "80%",
                  width: "27%",
                }}
              >
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FFA500",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    No of Findings
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "pink",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Closed Status
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "violet",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Open status
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#008000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    In Progress
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div>
            {/* No of completed projects */}
            <div className="chartParent">
              <div className="chartDiv">
                <div>
                  <Bar
                    data={{
                      // Name of the variables on x-axis for each bar
                      labels: [""],
                      datasets: [
                        {
                          // Label for bars
                          label: "Range",
                          // Data or value of your each variable
                          data: completedProjectCount,
                          // Color of each bar
                          backgroundColor: "#479cc7",
                          // Border color of each bar
                          // borderColor: ["#FFA500", "#008000", "#FF0000"],
                          borderWidth: 0.5,
                          maxBarThickness: 60,
                          //minBarLength:6,
                          barPercentage: 0.3,
                        },
                      ],
                    }}
                    // Height of graph
                    height={240}
                    width={500}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                          position: "bottom",
                          labels: {
                            usePointStyle: true,
                          },
                        },
                        datalabels: {
                          color: "white",
                          anchor: "end",
                          align: "end",
                        },
                      },

                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          display: false,
                          //beginAtZero:true
                        },
                        x: {
                          border: {
                            color: "#FFFFFF",
                          },
                          ticks: {
                            color: "#FFFFFF",
                          },
                        },
                        customGroups: {
                          axis: "x",
                          labels: ["Completed Project Report"],
                          grid: {
                            drawOnChartArea: false,
                          },
                          ticks: {
                            labelOffset: 10,
                            color: "white",
                            font: { size: 14 },
                          },
                        },
                      },
                      layout: {
                        padding: {
                          left: 130,
                          right: 130,
                          top: 40,
                          bottom: 10,
                        },
                      },
                    }}
                  />
                </div>
                <ul
                  style={{
                    padding: "0px",
                    margin: "0px",
                    color: "#ffffff",
                    position: "absolute",
                    left: "70%",
                  }}
                >
                  <div style={{ display: "flex", padding: "5px" }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#479cc7",
                        marginRight: "6px",
                      }}
                    ></div>{" "}
                    <div style={{ fontSize: "12px" }} className="Subbmissin">
                      completed projects
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {/* summary in table */}
            <table className="tstyle">
              <thead style={{ lineHeight: "24px" }}>
                <tr>
                  <th colSpan={4}>Health</th>
                  <th colSpan={4}>Findings</th>
                </tr>
                <tr>
                  <th>Quarter</th>
                  <th>Red </th>
                  <th>Amber </th>
                  <th>Green </th>
                  <th>Open</th>
                  <th>In-Progress</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Quarter 1</td>
                  <td>{quarter1RedCount}</td>
                  <td>{quarter1AmberCount}</td>
                  <td>{quarter1GreenCount}</td>

                  <td>{quarter1OpenCount}</td>
                  <td>{quarter1InprogressCount}</td>
                  <td>{quarter1CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 2</td>
                  <td>{quarter2RedCount}</td>
                  <td>{quarter2AmberCount}</td>
                  <td>{quarter2GreenCount}</td>

                  <td>{quarter2OpenCount}</td>
                  <td>{quarter2InprogressCount}</td>
                  <td>{quarter2CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 3</td>
                  <td>{quarter3RedCount}</td>
                  <td>{quarter3AmberCount}</td>
                  <td>{quarter3GreenCount}</td>

                  <td>{quarter3OpenCount}</td>
                  <td>{quarter3InprogressCount}</td>
                  <td>{quarter3CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 4</td>
                  <td>{quarter4RedCount}</td>
                  <td>{quarter4AmberCount}</td>
                  <td>{quarter4GreenCount}</td>

                  <td>{quarter4OpenCount}</td>
                  <td>{quarter4InprogressCount}</td>
                  <td>{quarter4CloseCount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadershipReport;
