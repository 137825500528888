import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./employees-list.scss";
import axios from 'axios';
import axiosInstance from '../../../httpInterceptor';

interface Employee {
  id: number;
  name: string;
  email: string;
  role: string;
  psNo: string;
  timeStamp: string;
}

const EmployeeTable: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [query, setQuery] = useState({
    name: "",
    psNo: "",
    email: "",
    role: ""
  });
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    axiosInstance.get(`${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`)
      .then(response => {
        setEmployees(response.data);
        setFilteredEmployees(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the employees!', error);
      });
  }, []);

  useEffect(() => {
    let filtered = employees;
    if (query.name) {
      filtered = filtered.filter(employee =>
        employee.name.toLowerCase().includes(query.name.toLowerCase())
      );
    }
    if (query.psNo) {
      filtered = filtered.filter(employee =>
        employee.psNo.toLowerCase().includes(query.psNo.toLowerCase())
      );
    }
    if (query.email) {
      filtered = filtered.filter(employee =>
        employee.email.toLowerCase().includes(query.email.toLowerCase())
      );
    }
    if (query.role) {
      filtered = filtered.filter(employee =>
        employee.role.toLowerCase().includes(query.role.toLowerCase())
      );
    }
    setFilteredEmployees(filtered);
  }, [query, employees]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="projectlistTableContainer">
      <div className="searchInputs">
        <div className="inputContainer">
          <input
            type="text"
            name="name"
            placeholder="Search Name"
            value={query.name}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="psNo"
            placeholder="Search PS No"
            value={query.psNo}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="email"
            placeholder="Search Email"
            value={query.email}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="role"
            placeholder="Search Role"
            value={query.role}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
      <table className="tstylee">
        <thead>
          <tr>
            <td>Name</td>
            <td>PS No</td>
            <td>Email</td>
            <td>Role</td>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.map(employee => (
            <tr key={employee.id}>
              <td>{employee.name}</td>
              <td>{employee.psNo}</td>
              <td>{employee.email}</td>
              <td>{employee.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;


