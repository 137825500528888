import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Sidebar from "./components/layout/sidebar/Sidebar";
import Login from "./components/pages/login/Login";
import LeadershipReport from "./components/pages/leadership-report/LeadershipReport";
import CreateQuestion from "./components/pages/create-question/CreateQuestion";
import AccessManagement from "./components/pages/access-management/AccessManagement";
import NewAssessment from "./components/pages/dashboard/new-assessment/NewAssessment";
import Home from "./components/pages/home/Home";
import ProtectedRoute from "./components/protectedroute/ProtectedRoute";
import AccessError from "./components/layout/access-error/AccessError";
import CreateProject from "./components/pages/dashboard/create-project/CreateProject";
import { useDispatch, useSelector } from "react-redux";
import { updateNewAssessmentVariable } from "./redux/slices/globalVariableSlice";
import { ToastContainer } from "react-toastify";
import CreateQuestion2 from "./components/pages/create-question/CreateQuestion";
import { checkTokenExpiry } from "./database";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const SESSION_TIMEOUT = 10 * 60 * 1000;

  const path = location.pathname;

  const showSidebar = sessionStorage.getItem("key");

  //check if the path is for reassessment then it update the global variable to true
  useEffect(() => {
    if (!path.includes("re-assessment")) {
      dispatch(updateNewAssessmentVariable(true));
    }
  }, [path]);

  //If no access token in localstorage and code in the url then it is navigating to login again
  useEffect(() => {
    if (!sessionStorage.getItem("PBT_ACCESS_TOKEN")) {
      if (!window.location.href.split("=")[1]) navigate("/login");
    }
  }, []);

  useEffect(() => {
    let timeout: any;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        logoutUser();
      }, SESSION_TIMEOUT);
    };

    const logoutUser = () => {
      sessionStorage.clear();
      window.location.assign(`${process.env.REACT_APP_COGNITOSSO_LOGOUT_URL}`);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);
    window.addEventListener("click", resetTimeout);

    resetTimeout();

    return () => {
      clearTimeout(timeout);

      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
      window.removeEventListener("click", resetTimeout);
    };
  }, []);

  setInterval(checkTokenExpiry, 60 * 1000);

  return (
    <div className="container">
      {showSidebar && <Sidebar />}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* protectedroute is for protecting the sub route, like if the user is
         genuine then it allows component to render otherwise it navigate to login page */}
        <Route element={<ProtectedRoute requiredPermission="dashboard" />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="dashboard" />}>
          <Route path="/dashboard/create-project" element={<CreateProject />} />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="dashboard" />}>
          <Route
            path="/dashboard/new-assessment/:proj_id"
            element={<NewAssessment />}
          />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="dashboard" />}>
          <Route
            path="/dashboard/re-assessment/:proj_id"
            element={<NewAssessment />}
          />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="question" />}>
          <Route path="/create-your-question" element={<CreateQuestion2 />} />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="leadership" />}>
          <Route path="/leadership-report" element={<LeadershipReport />} />
        </Route>
        <Route element={<ProtectedRoute requiredPermission="access" />}>
          <Route path="/access-management" element={<AccessManagement />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/Unauthorised" element={<AccessError />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
};

export default App;
