import React, { useState } from 'react';
import './newAssessment.scss';
import NewAssessmentForm from '../new-assessment-form/NewAssessmentForm';
import Header from '../../../layout/header/Header';
// import Modal from '../../../layout/modal-window/Modal';

const NewAssessment = () => {
  return (
    <div className="newAssessmentContainer">
      <Header />
      <NewAssessmentForm />
    </div>
  );
};

export default NewAssessment;
