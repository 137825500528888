import React, { useEffect, useState } from 'react';
import './createQuestion.scss';
import DropDownPopup from './DropDown/DropDown';
import { toast } from 'react-toastify';
import Header from '../../layout/header/Header';
import axiosInstance from '../../../httpInterceptor';
// import { db } from "../../../database";
import CustomDropdown from './customdropdown';
import Tooltip from './ToolTip/Tooltip';
 
type Question = {
  _id?: number;
  description: string;
  set: string;
  category: string;
  type: 'text' | 'dropdown' | 'radio' | 'checkbox' | null;
  values?: { option: string; response: string }[];
};
 
const CreateQuestion2: React.FC = () => {
  const [questionText, setQuestionText] = useState('');
  const [setText, setSetText] = useState('');
  const [viewTab, setViewTab] = useState(true);
 
  const [category, setCategory] = useState('');
  const [responseType, setResponseType] = useState<
    'text' | 'dropdown' | 'radio' | 'checkbox' | null
  >(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isOpen, setIsOpen] = useState<{ [key: string]: Boolean }>({});
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showDropDownPopup, setShowDropDownPopup] = useState(false);
  const [savedItems, setSavedItems] = useState<{ response: string }[]>([]);
  const [tempSavedItems, setTempSavedItems] = useState<{ response: string }[]>(
    []
  );
  const [inputType, setInputType] = useState('');
  const [tempInputType, setTempInputType] = useState('');
  // const [expandedCategory, setExpandedCategory] = useState<
  //   "General" | "Science" | "Math" | "History" | null
  // >(null);
 
  //fetching all the question from database......
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions`
        );
        setQuestions(response.data);
      } catch (error) {
        toast.error('Error fetching questions');
      }
    };
 
    fetchQuestions();
  }, []);
 
  //adding question to the database on click of add question button
  const addQuestion = async () => {
    if (questionText.trim() === '' || !category || !setText) {
      return;
    }
 
    const newQuestion: Question = {
      description: questionText,
      set: setText,
      category: category,
      type: responseType,
      values:
        responseType !== 'text'
          ? savedItems.map((item, index) => ({
              option: (index + 1).toString(),
              response: item.response,
            }))
          : [{ option: '1', response: '' }],
    };
    try {
      if (editingIndex !== null) {
        const response = await axiosInstance.put(
          `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${editingIndex}`,
          newQuestion
        );
        const updatedQuestions = [...questions];
        const currentUpdatedQuestionIndex = updatedQuestions.findIndex(
          (question) => question._id === editingIndex
        );
 
        updatedQuestions[currentUpdatedQuestionIndex] = response.data;
        setQuestions(updatedQuestions);
        setEditingIndex(null);
        setQuestionText('');
        setCategory('');
        setResponseType(null);
        setSavedItems([]);
        setTempSavedItems([]);
        toast.success('Question edited successfully');
      } else {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions`,
          newQuestion
        );
        // console.log(response, response.data[0], 'this');
        setQuestions([...questions, response.data[0]]);
        toast.success('Question added successfully');
      }
 
      setQuestionText('');
      setCategory('');
      setResponseType(null);
      setSavedItems([]);
      setTempSavedItems([]);
    } catch (error) {
      toast.error('Error adding/updating questions');
    }
    setSetText('');
    setViewTab(!viewTab);
  };
 
  const editQuestion = async (index: number) => {
    setIsEditMode(true);
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${index}`
    );
    // const question = questions[index];
    setQuestionText(data.description);
    setSetText(data.set);
    setCategory(data.category);
    setResponseType(data.type);
    setInputType(data.type);
    setTempInputType(data.type);
    const resArr = data.values.map((item: { response: string }) => {
      return { response: item.response };
    });
    setTempSavedItems(resArr);
    setEditingIndex(index);
    setViewTab(false);
  };
 
  const cancelEdit = () => {
    setQuestionText('');
    setSetText('');
    setIsEditMode(false);
    setCategory('');
    setResponseType(null);
    setEditingIndex(null);
    setTempSavedItems([]);
    setSavedItems([]);
  };
 
  const deleteQuestion = async (index: number) => {
    // const questionId = questions[index]._id;
 
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${index}`
      );
      const updatedQuestions = questions.filter(
        (question, i) => question._id !== index
      );
      setQuestions(updatedQuestions);
      toast.success('Question deleted successfully');
    } catch (error) {
      toast.error('Error deleting the question');
    }
  };
 
  const handleResponseTypeChange = (
    responseType: 'text' | 'dropdown' | 'radio' | 'checkbox'
  ) => {
    setResponseType(responseType);
    if (
      responseType === 'dropdown' ||
      responseType === 'radio' ||
      responseType === 'checkbox'
    ) {
      setShowDropDownPopup(true);
      setInputType(responseType);
    }
  };
 
  const toggleDropDownPopup = (type: string, valueArray: any) => {
    if (type !== tempInputType) {
      setSavedItems([]);
    }
    if (type === tempInputType) {
      setSavedItems(savedItems);
    }
    if (isEditMode && tempInputType === type) {
      setSavedItems(tempSavedItems);
    }
 
    setInputType(type);
 
    // if (savedItems.length > 0) {
    //   setTempSavedItems(savedItems);
    // } else {
    //   setSavedItems(valueArray);
    // }
    setShowDropDownPopup((prev) => !prev);
  };
 
  const handleSave = (items: { response: string }[]) => {
    setSavedItems(items);
    setTempInputType(inputType);
    setShowDropDownPopup(false);
  };
 
  const handleClose = () => {
    setShowDropDownPopup(false);
  };
 
  // const toggleCategory = (
  //   category: string
  // ) => {
  //   setExpandedCategory((prev) => (prev === category ? null : category));
  // };
 
  // const handleOpenClose = (category: string, p0?: { category: string; }) => {
  //   setIsOpen((prevIsOpen) => {
  //     const newIsOpen: { [key: string]: boolean } = {};
  //     Object.keys(prevIsOpen).forEach((key) => {
  //       newIsOpen[key] = key === category ? !prevIsOpen[key] : false;
  //     });
  //     newIsOpen[category] = !prevIsOpen[category]; // Ensure the clicked category toggles
  //     return newIsOpen;
  //   });
  //   cancelEdit();
  // };
 
  const handleOpenClose = (category: string) => { //it handles the open close state
    setIsOpen((prevState) => ({                   //it udates open state i pass previousstate as a argument besically it opens the previous state
      ...prevState,                               //here i copied the existing state inside prevstate spread operator
      [category]: !prevState[category],           // and lastly form this code i can toggle the state of category to visible the questions
    }));
    cancelEdit();
  };                                              //go to the 593 line which return the full operation where i toggle the states and see the questions under category.
 
 
 
 
  const groupQuestionsByCategory = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const category = question.category;
      if (!acc[category]) acc[category] = [];
      acc[category].push(question);
      return acc;
    }, {});
  };
  const groupQuestionsBySet = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const set = question.set;
      //console.log(set);
      if (!acc[set]) acc[set] = [];
      acc[set].push(question);
      return acc;
    }, {});
  };
 
  const groupArray = (questions: any) => {
    return questions.reduce((result: any, obj: any) => {
      const { set, category } = obj;
 
      if (!result[set]) {
        result[set] = {};
      }
      if (!result[set][category]) {
        result[set][category] = [];
      }
      result[set][category].push(obj);
      return result;
    }, {});
  };
 
 
  const groupedArray = groupArray(questions);
 
  const groupedQuestionsBySet = groupQuestionsBySet(questions);
  const groupedQuestionsByCategory = groupQuestionsByCategory(questions);
  //console.log(groupedQuestionsByCategory);
 
  ///////////////////Logic for Set suggestions
  const suggestions = Object.keys(groupedQuestionsBySet);
  const noSuggestionsMessage: string =
    'No matching set name found! Create a new one!';

  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  useEffect(() => {
    if (setText) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(setText.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }, [setText]);
 
  const handleClick = (suggestion: string) => {
    setSetText(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };
 
  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setSetText(filteredSuggestions[activeSuggestionIndex]);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestionIndex === 0) return;
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.key === 'ArrowDown') {
      if (activeSuggestionIndex === filteredSuggestions.length - 1) return;
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };
  /////////////Logic for Category suggestions
  const categorySuggestions = Object.keys(groupedQuestionsByCategory);
  console.log(categorySuggestions);
  const noCategorySuggestionsMessage: string =
    'No matching category name found! Create a new one!';
 
  const [filteredCategorySuggestions, setFilteredCategorySuggestions] =
    useState<string[]>([]);
  const [showCategorySuggestions, setShowCategorySuggestions] = useState(false);
  const [activeCategorySuggestionIndex, setActiveCategorySuggestionIndex] =
    useState(0);
  useEffect(() => {
    if (setText) {
      const filtered = (groupedArray[setText] ? Object.keys(groupedArray[setText]) : []).filter((suggestion) =>
        suggestion.toLowerCase().includes(category.toLowerCase())
      );
      setFilteredCategorySuggestions(filtered);
      setShowCategorySuggestions(true);
    } else {
      setFilteredCategorySuggestions([]);
      setShowCategorySuggestions(false);
    }
  }, [category , setText]);//only category

  const handleCategoryClick = (suggestion: string) => {
    setCategory(suggestion);
    setShowCategorySuggestions(false);
    setFilteredCategorySuggestions([]);
  };
 
  const handleCategoryKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setCategory(filteredCategorySuggestions[activeCategorySuggestionIndex]);
      setFilteredCategorySuggestions([]);
      setShowCategorySuggestions(false);
    } else if (e.key === 'ArrowUp') {
      if (activeCategorySuggestionIndex === 0) return;
      setActiveCategorySuggestionIndex(activeCategorySuggestionIndex - 1);
    } else if (e.key === 'ArrowDown') {
      if (
        activeCategorySuggestionIndex ===
        filteredCategorySuggestions.length - 1
      )
        return;
      setActiveCategorySuggestionIndex(activeCategorySuggestionIndex + 1);
    }
  };
 
  return (
    <div className="app">
      <Header />
      {!viewTab ? (
        <div className="newQues-box">
          <p className="text1">
            {editingIndex !== null ? 'Edit Question' : 'Create A New Question'}
          </p>
          <div>
          <br />
            <label>Add New Set / Select Existing Set:</label>
            <Tooltip elements={suggestions} />
            <br />
            <input
              className="textfield"
              type="text"
              value={setText}
              onChange={(e) => setSetText(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="dropDown">
              {showSuggestions && setText && (
                <ul>
                  {filteredSuggestions.length ? (
                    filteredSuggestions.map((suggestion, index) => (
                      <li
                        key={suggestion}
                        onClick={() => handleClick(suggestion)}
                        className={
                          index === activeSuggestionIndex ? 'active' : ''
                        }
                      >
                        {suggestion}
                      </li>
                    ))
                  ) : (
                    <li className="noSuggMsg">{noSuggestionsMessage}</li>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div>
            <label>Add New Category / Select Existing Category:</label>
 
            <Tooltip elements={filteredCategorySuggestions} />
            <br />
            <input
              className="textfield"
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              onKeyDown={handleCategoryKeyDown}
            />
            <div className="dropDown">
              {showCategorySuggestions && category && (
                <ul>
                  {filteredCategorySuggestions.length ? (
                    filteredCategorySuggestions.map((suggestion, index) => (
                      <li
                        key={suggestion}
                        onClick={() => handleCategoryClick(suggestion)}
                        className={
                          index === activeCategorySuggestionIndex
                            ? 'active'
                            : ''
                        }
                      >
                        {suggestion}
                      </li>
                    ))
                  ) : (
                    <li className="noSuggMsg">
                      {noCategorySuggestionsMessage}
                    </li>
                  )}
                </ul>
              )}
            </div>
            {/* <select
              className="textfield1"
              value={category || ''}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled>
                Select a category
              </option>
              <option value="Contract">Contract</option>
              <option value="Scope of Work">Scope of Word</option>
              <option value="Resourcing">Resourcing</option>
              <option value="Execution">Execution</option>
              <option value="Infrastructure">Infrastructure</option>
              <option value="Risks">Risks</option>
              <option value="Customer Engagement">Customer Engagement</option>
            </select> */}
          </div>
          
          <div>
            <label>Question Text: </label>
            <br />
            <input
              className="textfield"
              type="text"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
            />
          </div>
          <div className="response-options">
            <label>Response Type: </label>
            <div>
              <label>
                <input
                  type="Radio"
                  value="text"
                  checked={responseType === 'text'}
                  onChange={() => handleResponseTypeChange('text')}
                />
                Text
              </label>
              <label>
                <input
                  type="Radio"
                  value="dropdown"
                  checked={responseType === 'dropdown'}
                  onChange={(e) =>
                    handleResponseTypeChange(
                      e.target.value as
                        | 'text'
                        | 'dropdown'
                        | 'radio'
                        | 'checkbox'
                    )
                  }
                  onClick={() => toggleDropDownPopup('dropdown', [])}
                />
                Dropdown
              </label>
              <label>
                <input
                  type="Radio"
                  value="radio"
                  checked={responseType === 'radio'}
                  onChange={(e) =>
                    handleResponseTypeChange(
                      e.target.value as
                        | 'text'
                        | 'dropdown'
                        | 'radio'
                        | 'checkbox'
                    )
                  }
                  onClick={() => toggleDropDownPopup('radio', [])}
                />
                Radio
              </label>
              <label>
                <input
                  type="Radio"
                  value="checkbox"
                  checked={responseType === 'checkbox'}
                  onChange={(e) =>
                    handleResponseTypeChange(
                      e.target.value as
                        | 'text'
                        | 'dropdown'
                        | 'radio'
                        | 'checkbox'
                    )
                  }
                  onClick={() => toggleDropDownPopup('CheckBox', [])}
                />
                CheckBox
              </label>
            </div>
          </div>
          <button
            className="Addbox"
            onClick={addQuestion}
            disabled={questionText.trim() === ''}
          >
            {editingIndex !== null ? 'Save Question' : 'Add Question +'}
          </button>
          {showDropDownPopup && (
            <DropDownPopup
              onSave={handleSave}
              savedItems={savedItems}
              onClose={handleClose}
              inputType={inputType}
              rowEdit={[]}
            />
          )}
        </div>
      ) : (
        <div className="set-section">
          {Object.keys(groupedArray).length > 0 &&
            Object.keys(groupedArray).map((set, index) => (
             <div key={index}>
              <div
                  className="questionBtn"
                  onClick={() => handleOpenClose(set)}
                >
                  <p>SET - {set.toUpperCase()}</p>
                  {isOpen[set] ? (
                    <i className="fa-solid fa-chevron-down"></i>
                  ) : (
                    <i className="fa-solid fa-chevron-right"></i>
                  )}
              </div>
                  {isOpen[set] && (
                   <>
                    {/* {Object.values(groupedArray).map((category: any) => (
                      <div>
                        <table className="question-table">
                          <tbody>
                            <tr key={index} className="table-row">
                              {Object.values(category).map((item: any) => (
                                <td>{item.description}</td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))} */}
                   
                 {
                  Object.keys(groupedArray[set]).map((category, index) => (
                  <div key={index}>
                       <div
                         className="questionBtnn"
                         onClick={() => handleOpenClose(`${set} - ${category}`)}
                        >
                         <p>CATEGORY - {category.toUpperCase()}</p>
                         {isOpen[`${set} - ${category}`] ? (
                           <i className="fa-solid fa-chevron-down"></i>
                           ) : (
                           <i className="fa-solid fa-chevron-right"></i>
                           )}
                        </div>
                     {isOpen[`${set} - ${category}`] && (
                     <div>
                      <table className="question-table">
                        <thead className="head">
                          <tr>
                            <td>Questions</td>
                            <td>Response Type</td>
                            <td>Modify</td>
                            <td>Delete</td>
                          </tr>
                        </thead>
 
                        <tbody>
                            {groupedQuestionsBySet[set].filter((question:any) =>
                                    question.category === category
                                )
                                 .map((question:any, idx:any) => (
                                <tr key={idx} className="table-row">
                                <td>{question.description}</td>
                                <td className="response-options">
                                  {/* <input type="Radio" checked disabled /> */}
                                  {question.type.toUpperCase()}
                                </td>
                                <td>
                                  {editingIndex === question._id ? (
                                    <button
                                      onClick={cancelEdit}
                                      className="saveicon"
                                    >
                                      <img
                                        src={require('../../../images/cross.png')}
                                        alt=""
                                        style={{ color: '#000000' }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="modifyDelIcon"
                                      onClick={() => editQuestion(question._id)}
                                    >
                                      <img
                                        src={require('../../../images/modify.png')}
                                        alt=""
                                      />
                                    </button>
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="modifyDelIcon"
                                    onClick={() => deleteQuestion(question._id)}
                                  >
                                    <img
                                      src={require('../../../images/delete.png')}
                                      alt=""
                                    />
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                       </table>
                    </div>
                    )}
                  </div>
                 ))}
                </>
              )}
         
            </div>
            ))}
        </div>
      )}
      <button className="switchTab" onClick={() => setViewTab(!viewTab)}>
        {viewTab ? '+New Question' : 'Back'}
      </button>
    </div>
  );
};
 
export default CreateQuestion2;