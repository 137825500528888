import React, { useEffect, useState } from "react";
import "./history.scss";
import axiosInstance from "../../../../../httpInterceptor";

interface Question {
  id: number;
  created_date: string;
  created_by: string;
  description: string;
  response: string;
  remarks: string;
}

interface HistoryProps {
  projectid: string | undefined;
}

interface QuestionGroup extends Array<Question> {}

const History: React.FC<HistoryProps> = ({ projectid }) => {
  const [questions, setQuestions] = useState<QuestionGroup[]>([]);
  const [isOpen, setIsOpen] = useState<{ [key: number]: boolean }>({});
  const [errorMessage, setErrorMessage] = useState("");

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-history-details/${projectid}`
  //     )
  //     .then((res) => {
  //       setQuestions(res.data);
  //       if (res.data.message) {
  //         setErrorMessage(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [projectid]);

  useEffect(() => {
    const getHistoryDetailsByProjectId = async () => {
      try {
        let length;
        let flag = false;
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectid}`
        );

        if (data.length === 0) {
          return setErrorMessage("History not found");
        }

        const assessmentDetails = await Promise.all(
          data.map(async (result: any) => {
            length = result.assessmentdetails.length;
            if (length > 1) {
              flag = true;
              result.assessmentdetails.sort((a: any, b: any) => {
                return (
                  new Date(b.created_date).getTime() -
                  new Date(a.created_date).getTime()
                );
              });
              result.assessmentdetails.shift();
              const assessmentPromises = result.assessmentdetails.map(
                async (detail: any) => {
                  const qnaPromises = detail.qnadetails.map(
                    async (item: any) => {
                      const question = await axiosInstance.get(
                        `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${item.questionid}`
                      );
                      return {
                        questionid: item.questionid,
                        description: question.data.description,
                        response: item.response,
                        remarks: item.remarks,
                        created_by: detail.created_by,
                        created_date: detail.created_date,
                      };
                    }
                  );

                  return Promise.all(qnaPromises);
                }
              );
              return Promise.all(assessmentPromises);
            }
          })
        );
        const flattenedDetails = assessmentDetails.flat(1);
        if (flag) {
          setQuestions(flattenedDetails);
        } else {
          setErrorMessage("History not found");
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    };

    getHistoryDetailsByProjectId();
  }, [projectid]);

  const handleOpenClose = (index: number) => {
    setIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}th ${month}, ${year}`;
  };

  return (
    <div className="history">
      <div className="historySearchInputBox">
        {/* <div>
          <i className="fa-brands fa-searchengin"></i>
          <input type="text" />
        </div> */}
      </div>
      <div className="historyBodyContainer">
        {questions.length > 0 ? (
          questions.map((questionGroup, index) => (
            <div key={index}>
              <div
                className="historyBtn"
                onClick={() => handleOpenClose(index)}
              >
                <p>{formatDate(questionGroup[0]?.created_date)}</p>
                <div>
                  <p>Submitted by {questionGroup[0]?.created_by}</p>
                  {!isOpen[index] ? (
                    <i className="fa-solid fa-chevron-right"></i>
                  ) : (
                    <i className="fa-solid fa-chevron-down"></i>
                  )}
                </div>
              </div>
              {isOpen[index] && (
                <div>
                  <table className="historyTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Questions</td>
                        <td>Response</td>
                        <td>Remarks</td>
                      </tr>
                    </thead>
                    <tbody className="assessmentBody">
                      {questionGroup.map((item, idx) => (
                        <tr>
                          <td style={{ width: "10%" }}>{idx + 1}</td>
                          <td>{item.description}</td>
                          <td>{item.response}</td>
                          <td style={{ width: "30%" }}>{item.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))
        ) : (
          <h2 className="errorMessage">{errorMessage}</h2>
        )}
      </div>
    </div>
  );
};

export default History;
