import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../layout/loader/Loading";

const Login = () => {
  useEffect(() => {
    window.location.assign(`${process.env.REACT_APP_COGNITOSSO_URL}`);
  }, []);
  return <Loading />;
};

export default Login;
