import React, { useState, useEffect } from "react";
import "./findings.scss";
import { useParams } from "react-router-dom";
import Observations from "./observations/Observations";
import Comments from "./comments/Comments";
import { db } from "../../../../../database";
import Loading from "../../../../layout/loader/Loading";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../httpInterceptor";

interface Comment {
  created_by?: string;
  created_date?: string;
  comment?: string;
}

interface Finding {
  srlNo: string;
  observations: string;
  type: string;
  assignedBy: string;
  assignedTo: string;
  status: string;
  comments?: Comment[];
  created_date: string;
}
interface ObservationData {
  projectid: string | undefined;
  observations: Partial<Finding>[];
}
const Findings: React.FC = () => {
  const { statusOptions, typeOptions } = db;
  const params = useParams();
  const [findings, setFindings] = useState<Finding[]>([]);
  const [id, setId] = useState<number>(0);
  const [statuss, setStatuss] = useState<string>("");

  const [findingsData, setFindingsData] = useState<any[]>([]);
  const [newFinding, setNewFinding] = useState<Partial<Finding> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newFindingDate, setNewFindingDate] = useState(
    `${new Date().toISOString().split("T")[0]}`
  );
  const [newComment, setNewComment] = useState<string>("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [expandedObservations, setExpandedObservations] = useState<
    number | null
  >(null);
  const [expandedComments, setExpandedComments] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [srlNo, setSrlNo] = useState<number>(1);
  const [openadd, setOpenAdd] = useState<boolean>(false);
  // const[assignedTo,setAssignedTo] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [names, setNames] = useState<string[]>([]);
  //const[query,setQuery]=useState<string>('');

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };
  const handleAddClick = () => {
    setNewFinding({
      srlNo: String(
        findingsData.length < 1
          ? srlNo
          : findingsData[0].observations.length + 1
      ),
      observations: "",
      type: "",
      assignedBy: `${sessionStorage.getItem(
        "PBT_FIRSTNAME"
      )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
      assignedTo: "",
      status: "",
      comments: [],
      created_date: newFindingDate,
    });
    setIsEditing(false);
    setOpenAdd(true);
  };

  const handleEditClick = (index: number, id: number) => {
    setId(id);
    setNewFinding(findingsData[0].observations[index]);
    setEditingIndex(index);
    setIsEditing(true);
    if (findingsData[0].observations[index] === index) {
      setNewFinding(null);
    }
    setOpenAdd(false);
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    field: keyof Finding
  ) => {
    if (newFinding) {
      setNewFinding({
        ...newFinding,
        [field]: e.target.value,
      });
      if (field === "assignedTo") {
        fetchSuggestions(e.target.value);
      }
    }
  };
  const handleCancelClick = () => {
    setNewFinding(null);
    setEditingIndex(null);
    setIsEditing(false);
    setOpenAdd(false);
  };

  const fetchFindingsData = async () => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/observations/${params.proj_id}`
      );
      setFindingsData(res.data);
    } catch (error) {
      console.error("Error fetching the data", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSuggestions = async (search: string) => {
    if (!search) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`
      );
      const result = response.data
        .filter((user: any) =>
          user.name.toLowerCase().startsWith(search.toLowerCase())
        )
        .map((user: any) => user.name);
      setSuggestions(result);
      setNames(result);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  //console.log(names);

  const handleSaveClick = async () => {
    const newCommentObject: Comment = {
      created_by: `${sessionStorage.getItem(
        "PBT_FIRSTNAME"
      )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
      created_date: `${new Date().toISOString().split("T")[0]}`,
      comment: newComment,
    };
    if (newFinding) {
      const updatedFinding: Finding = {
        ...newFinding,
        comments: [...(newFinding.comments || []), newCommentObject],
      } as Finding;
      if (editingIndex !== null) {
        setFindingsData((prevFindings) => {
          const updatedFindings = [...prevFindings[0].observations];
          updatedFindings[editingIndex] = {
            ...(newFinding as Finding),
            srlNo: prevFindings[0].observations[editingIndex].srlNo,
          };
          return [
            {
              ...prevFindings[0],
              observations: updatedFindings,
            },
          ];
        });
        try {
          const UpdatedObservationData = {
            projectid: params.proj_id,
            srlNo: id,
            status: newFinding.status,
            comments: [newCommentObject],
          };
          if (
            findingsData[0].observations.status !== newFinding.status &&
            newComment === ""
          ) {
            toast.error("Please Add a Comment To Save");
          } else {
            console.log(UpdatedObservationData);
            const res = await axiosInstance.patch(
              `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/observations/${params.proj_id}`,
              UpdatedObservationData
            );
            fetchFindingsData();
            toast.success("Data saved successfully");
            console.log("updatedInformation added in db", res);
          }
          setNewComment("");
        } catch (error) {
          console.log(error);
        }
        setNewFinding(null);
        setEditingIndex(null);
        setOpenAdd(false);
      } else {
        setFindingsData((prevFindings) => [
          ...prevFindings,
          newFinding as Finding,
          updatedFinding,
        ]);
        //console.log(newFinding.assignedTo);
        //console.log(suggestions);

        try {
          const UpdatedObservationData: ObservationData = {
            projectid: params.proj_id,
            observations: [updatedFinding],
          };
          if (
            !newFinding.observations ||
            !newFinding.type ||
            !newFinding.assignedTo ||
            !newFinding.status
            // !newCommentObject.comment
          ) {
            toast.error("Please Fill All The Fields to Save");
          } else if (!names.includes(newFinding.assignedTo)) {
            toast.error("Please Select a Name from Suggestion");
          } else {
            const res = await axiosInstance.post(
              `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/observations`,
              UpdatedObservationData
            );
            fetchFindingsData();
            toast.success("Data saved successfully");
          }
          setNewComment("");
        } catch (error) {
          console.log(error);
        }
        setNewFinding(null);
        setOpenAdd(false);
      }
    }
  };
  useEffect(() => {
    fetchFindingsData();
  }, [params.proj_id]);

  const handleSuggestionClick = (suggestion: string) => {
    setNewFinding((prevState) => ({
      ...prevState,

      assignedTo: suggestion,
    }));
    setSuggestions([]);
  };

  const handleSuggestionBlur = () => {
    setSuggestions([]);
  };
  const showError = () => {
    if (newFinding?.assignedTo && suggestions.includes(newFinding.assignedTo)) {
      return toast.error("message");
    }
    return null;
  };

  const [selectedContent, setSelectedContent] = useState<string | null>(null);
  const [isObservationsOpen, setIsObservationsOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [selectedCommentContent, setCommentSelectedContent] = useState<
    string | null
  >(null);

  const handleOpenObservations = (content: string, index: number) => {
    setSelectedContent(content);
    setExpandedObservations(index);
    setIsObservationsOpen(true);
  };
  const handleCloseObservations = () => {
    setIsObservationsOpen(false);
    //setSelectedContent(null)
  };
  const handleOpenComments = (content: any, index: number) => {
    setIsCommentsOpen(true);
    setExpandedComments(index);
    setCommentSelectedContent(content);
  };
  const handleCloseComments = () => {
    setIsCommentsOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="findings">
      <table className="findingsTable">
        <thead>
          <tr>
            <td>#</td>
            <td>Observations</td>
            <td>Type</td>
            <td>Assigned By</td>
            <td>Assigned To</td>
            <td>Status</td>
            <td>Comments</td>
            <td>Open Date</td>
            <td>Edit</td>
          </tr>
        </thead>
        <tbody>
          {findingsData.length > 0 &&
            Array.isArray(findingsData[0].observations) &&
            findingsData[0].observations.map((finding: any, index: number) => (
              <tr key={index}>
                <td style={{ width: "2%" }}>{finding.srlNo} </td>
                <td className="observations" style={{ width: "20%" }}>
                  {finding.observations.length > 70
                    ? `${finding.observations.slice(0, 70)}...`
                    : `${finding.observations}`}
                  <p
                    key={index}
                    className="observations-popup"
                    onClick={() =>
                      handleOpenObservations(finding.observations, index)
                    }
                  >
                    {finding.observations.length > 70 && <span>more</span>}
                  </p>
                  <Observations
                    isOpen={
                      isObservationsOpen && expandedObservations === index
                    }
                    onClose={handleCloseObservations}
                    content={finding.observations}
                  />
                </td>
                <td style={{ width: "10%" }}>{finding.type}</td>
                <td style={{ width: "12%" }}>{finding.assignedBy}</td>
                <td style={{ width: "12%" }}>{finding.assignedTo}</td>
                <td style={{ width: "10%" }}>
                  {editingIndex === index ? (
                    <select
                      className="statusInput"
                      //type="text"
                      defaultValue={finding.status}
                      //readOnly={isEditing === true}
                      onChange={(e) => handleChange(e, "status")}
                    >
                      <option defaultValue="Status">Status</option>
                      {statusOptions.map((status) => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    finding.status
                  )}
                </td>

                <td style={{ width: "20%" }}>
                  {editingIndex === index ? (
                    <textarea
                      className="commentsInput"
                      //defaultValue={finding.comments[finding.comments.length - 1].comment}
                      onChange={handleCommentChange}
                    />
                  ) : (
                    <>
                      {finding.comments[finding.comments.length - 1].comment
                        .length > 50
                        ? `${finding.comments[
                            finding.comments.length - 1
                          ].comment.slice(0, 50)}...`
                        : finding.comments[finding.comments.length - 1].comment
                            .length >= 1
                        ? `${
                            finding.comments[finding.comments.length - 1]
                              .comment
                          }...`
                        : `${
                            finding.comments[finding.comments.length - 1]
                              .comment
                          }`}
                  
                      <p
                        className="comments-popup"
                        onClick={() =>
                          handleOpenComments(finding.comments, index)
                        }
                      >
                        {finding.comments.length > 1 ? (
                          <span>Earlier Comments</span>
                        ) : (
                          <span>
                            {finding.comments &&
                            finding.comments[finding.comments.length - 1]
                              .comment.length >= 1
                              ? "Details"
                              : ""}
                          </span>
                        )}

                      </p>
                      <Comments
                        isOpen={isCommentsOpen && expandedComments === index}
                        onClose={handleCloseComments}
                        content={finding.comments}
                      />
                    </>
                  )}
                </td>
                <td style={{ width: "10%" }}>{finding.created_date}</td>
                {openadd === false ? (
                  <td>
                    {editingIndex === index ? (
                      <i
                        className="fa-solid fa-xmark"
                        style={{ cursor: "pointer" }}
                        onClick={handleCancelClick}
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-pen-to-square"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditClick(index, finding.srlNo)}
                      ></i>
                    )}
                  </td>
                ) : null}
              </tr>
            ))}
          {newFinding !== null && editingIndex === null && (
            <tr>
              <td style={{ width: "2%" }}>{newFinding.srlNo}</td>
              <td style={{ width: "20%" }}>
                <textarea
                  className="observationsInput"
                  value={newFinding.observations}
                  required
                  onChange={(e) => handleChange(e, "observations")}
                />
              </td>
              <td style={{ width: "11%" }}>
                <select
                  className="typeInput"
                  value={newFinding.type}
                  required
                  onChange={(e) => handleChange(e, "type")}
                >
                  <option defaultValue="Type">Type</option>
                  {typeOptions.map((type: any) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </td>
              <td style={{ width: "12%" }}>
                <p className="assignedByInput">{newFinding.assignedBy}</p>
              </td>
              <td className="input-container" style={{ width: "12%" }}>
                <input
                  className="assignedToInput"
                  type="text"
                  value={newFinding?.assignedTo ?? ""}
                  onChange={(e) => handleChange(e, "assignedTo")}
                  onFocus={(e) => fetchSuggestions(e.target.value)}
                  onBlur={handleSuggestionBlur}
                  required
                  autoComplete="off"
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions">
                    {suggestions.map((suggention: string, index: number) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSuggestionClick(suggention)}
                      >
                        {suggention}
                      </li>
                    ))}
                  </ul>
                )}
              </td>
              <td style={{ width: "11%" }}>
                <select
                  className="statusInput"
                  // type="select"
                  value={newFinding.status}
                  required
                  onChange={(e) => handleChange(e, "status")}
                >
                  <option defaultValue="Status">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </td>
              <td style={{ width: "20%" }}>
                <textarea
                  className="commentsInput"
                  //value={newComment}
                  onChange={handleCommentChange}
                />
              </td>
              <td style={{ width: "10%" }}>
                <input
                  className="dateInput"
                  type="date"
                  value={newFinding.created_date}
                  required
                  onChange={(e) => handleChange(e, "created_date")}
                  max={new Date().toISOString().split("T")[0]}
                />
              </td>
              <td>
                <i
                  className="fa-solid fa-xmark"
                  style={{ cursor: "pointer" }}
                  onClick={handleCancelClick}
                ></i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="findingsbtn">
        {openadd === false && !isEditing && (
          <div
            className="addbtn"
            onClick={handleAddClick}
            style={{ cursor: "pointer" }}
          >
            Add +
          </div>
        )}
        <div
          className="savebtn"
          onClick={handleSaveClick}
          style={{ cursor: "pointer" }}
        >
          Save
        </div>
      </div>
    </div>
  );
};

export default Findings;
