import React, { useEffect, useState } from "react";
import "./questions.scss";
import Loading from "../../../../layout/loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import { updateNewAssessmentVariable } from "../../../../../redux/slices/globalVariableSlice";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../../../httpInterceptor";
import { toast } from "react-toastify";

type Response = string | string[];

interface QuestionResponse {
  set_name: string;
  category: string;
  questionid: string;
  response: Response;
  remarks: string;
}

const Question = ({ onData, projectid, setname }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState<any[]>([]);
  const [responses, setResponses] = useState<QuestionResponse[]>([]);
  const [assessmentData, setAssessmentData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState<{ [key: string]: Boolean }>({});
  // const [groupedQuestions, setGroupedQuestions] = useState<any>({});
  // const [groupedLatestAssessment, setGroupedLatestAssessment] = useState<any>(
  //   {}
  // );

  let newAssessmentVar = JSON.parse(
    sessionStorage.getItem("newAssessment") || "false"
  );

  useEffect(() => {
    // Load responses from local storage
    const savedResponses = JSON.parse(
      localStorage.getItem(`responses_${projectid}`) || "[]"
    );
    if (savedResponses.length > 0) {
      setResponses(savedResponses);
    }
  }, [projectid]);

  // useEffect(() => {
  //   if (assessmentData.length > 0) {
  //     const some = assessmentData[0]?.assessmentdetails?.[0]?.qnadetails;
  //     setGroupedQuestions(groupQuestionsByCategory(some));
  //   }
  //   if (questions.length > 0) {
  //     setGroupedQuestions(groupQuestionsByCategory(questions));
  //   }
  // }, [questions, assessmentData]);

  const fetchQuestions = async () => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions`
      );
      const rs = res.data.filter((item: any) => item.set === setname);
      setQuestions(rs);
      // setGroupedQuestions(groupQuestionsByCategory(res.data));
      // setGroupedQuestions(groupQuestionsBySet(res.data));
    } catch (error) {
      console.error("Error fetching the data", error);
      setError("Error fetching the data");
    } finally {
      setLoading(false);
    }
  };

  const fetchAssessmentData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectid}`
      );

      if (data.length === 0 || !data[0].assessmentdetails.length) {
        dispatch(updateNewAssessmentVariable(true));
        localStorage.removeItem(`responses_${projectid}`);
        setResponses([]);
        return;
      }
      const len = data[0].assessmentdetails.length;
      const latestDetails = data[0].assessmentdetails[len - 1];
      if (!latestDetails.qnadetails.length) {
        localStorage.removeItem(`responses_${projectid}`);
        setResponses([]);
        return;
      }
      const qnadetailsWithQuestions = await Promise.all(
        data[0].assessmentdetails[len - 1].qnadetails.map(
          async (item: {
            questionid: string;
            response: string;
            remarks: string;
            category: string;
            set_name: string;
          }) => {
            const question = await axiosInstance.get(
              `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${item.questionid}`
            );
            return {
              questionid: item.questionid,
              category: question.data.category,
              set_name: question.data.set,
              description: question.data.description,
              type: question.data.type,
              response: item.response,
              remarks: item.remarks,
            };
          }
        )
      );

      const {
        assessmentdate,
        projectstatus,
        reasonforchange,
        created_by,
        modified_by,
        created_date,
        modified_date,
      } = data[0].assessmentdetails[len - 1];

      const latestAssessment = {
        projectid,
        assessmentdetails: [
          {
            assessmentdate,
            projectstatus,
            reasonforchange,
            created_by,
            created_date,
            modified_by,
            modified_date,
            qnadetails: qnadetailsWithQuestions,
          },
        ],
      };
      setAssessmentData([latestAssessment]);

      // setGroupedLatestAssessment(
      // groupQuestionsByCategory(qnadetailsWithQuestions)
      // groupQuestionsBySet(qnadetailsWithQuestions)
      // );
    } catch (error) {
      console.error("Error fetching the data", error);
      setError("Error fetching the data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (newAssessmentVar === false) {
      fetchAssessmentData();
    } else if (newAssessmentVar === true) {
      fetchQuestions();
    }
  }, [newAssessmentVar]);

  useEffect(() => {
    if (
      newAssessmentVar === true &&
      location.pathname.includes("re-assessment") &&
      assessmentData.length > 0 &&
      assessmentData[0]?.assessmentdetails?.[0]?.qnadetails
    ) {
      const res = assessmentData[0].assessmentdetails[0].qnadetails.map(
        (qna: any) => {
          const { description, ...rest } = qna;
          return rest;
        }
      );
      setResponses(res);
    }
  }, [assessmentData, newAssessmentVar, location.pathname]);

  const sendResponses = () => {
    onData(responses, Object.keys(groupedByLatestCategory).length);
    // Save responses to local storage
    localStorage.setItem(`responses_${projectid}`, JSON.stringify(responses));
  };

  const updateResponse = (
    questionid: string,
    response: Response,
    category: string
  ) => {
    setResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex(
        (q) => q.questionid === questionid
      );
      const updatedResponses = [...prevResponses];
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = {
          ...updatedResponses[existingResponseIndex],
          response,
        };
      } else {
        updatedResponses.push({
          set_name: setname,
          category,
          questionid,
          response,
          remarks: "",
        });
      }
      localStorage.setItem(
        `responses_${projectid}`,
        JSON.stringify(updatedResponses)
      );
      return updatedResponses;
    });
  };

  const updateRemarks = (
    questionid: string,
    remarks: string,
    category: string
  ) => {
    setResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex(
        (q) => q.questionid === questionid
      );
      const updatedResponses = [...prevResponses];
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = {
          ...updatedResponses[existingResponseIndex],
          remarks,
        };
      } else {
        updatedResponses.push({
          set_name: setname,
          category,
          questionid,
          response: "",
          remarks,
        });
      }
      localStorage.setItem(
        `responses_${projectid}`,
        JSON.stringify(updatedResponses)
      );
      return updatedResponses;
    });
  };

  const getResponseByQuestionId = (id: string): Response => {
    const res = responses.find((res) => res.questionid === id);
    if (res) {
      return res.response;
    }
    return "";
  };

  const handleOpenClose = (set: string) => {
    setIsOpen((prevIsOpen) => {
      const newIsOpen: { [key: string]: boolean } = {};
      Object.keys(prevIsOpen).forEach((key) => {
        newIsOpen[key] = key === set ? !prevIsOpen[key] : false;
      });
      newIsOpen[set] = !prevIsOpen[set]; // Ensure the clicked category toggles
      return newIsOpen;
    });
  };

  const groupQuestionsByCategory = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const category = question.category;
      if (!acc[category]) acc[category] = [];
      acc[category].push(question);
      return acc;
    }, {});
  };

  // const groupQuestionsBySet = (questions: any) => {
  //   return questions.reduce((acc: any, question: any) => {
  //     const set = question.set;
  //     if (!acc[set]) acc[set] = [];
  //     acc[set].push(question);
  //     return acc;
  //   }, {});
  // };

  if (loading) {
    return <Loading />;
  }
  let setValue: string;
  setValue = setname;

  // function assignFinalValue() {
  //   const test = Object.values(groupedQuestions)
  //     .map((item: any) => {
  //       return item.filter(
  //         (obj: { set_name: any }) => obj.set_name === setValue
  //       );
  //     })
  //     .filter((item) => item.length > 0)
  //     .reduce((acc, curr, i) => {
  //       acc[i] = curr;
  //       return acc;
  //     }, {});
  //   return test;
  // }
  // const test = assignFinalValue();

  let groupedByLatestCategory: any;

  if (newAssessmentVar === true) {
    groupedByLatestCategory = groupQuestionsByCategory(questions);
  }
  if (newAssessmentVar === false) {
    groupedByLatestCategory = groupQuestionsByCategory(
      assessmentData[0].assessmentdetails[0].qnadetails
    );
  }

  return (
    <div className="questions">
      {newAssessmentVar === true &&
        Object.keys(groupedByLatestCategory).map((category, index) => (
          <div key={index}>
            <div
              className="questionBtn"
              onClick={() => handleOpenClose(category)}
            >
              <p>{category}</p>

              {isOpen[category] ? (
                <i className="fa-solid fa-chevron-down"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )}
            </div>
            {isOpen[category] && (
              <div>
                <table className="questionTable" style={{}}>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>Questions</td>
                      <td className="responseIsComplusory">Response</td>
                      <td>Remarks</td>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedByLatestCategory[category].map(
                      (question: any, idx: any) => (
                        <tr key={question._id}>
                          <td style={{ width: "10%" }}>{idx + 1}</td>
                          <td style={{ width: "32%", textAlign: "left" }}>
                            {question.description}
                          </td>
                          <td style={{ width: "30%" }}>
                            {question.type === "dropdown" ? (
                              <select
                                name="dropdown"
                                value={
                                  (getResponseByQuestionId(
                                    question._id
                                  ) as string) || "--Select--"
                                }
                                onChange={(e) =>
                                  updateResponse(
                                    question._id,
                                    e.target.value,
                                    question.category
                                  )
                                }
                              >
                                <option value="--Select--">--Select--</option>
                                {question.values.map((item: any) => (
                                  <option
                                    value={item.response}
                                    key={item.option}
                                  >
                                    {item.response}
                                  </option>
                                ))}
                              </select>
                            ) : question.type === "text" ? (
                              <input
                                type="text"
                                name="text"
                                placeholder="Enter the Answer"
                                value={
                                  (getResponseByQuestionId(
                                    question._id
                                  ) as string) || ""
                                }
                                onChange={(e) =>
                                  updateResponse(
                                    question._id,
                                    e.target.value,
                                    question.category
                                  )
                                }
                              />
                            ) : question.type === "radio" ? (
                              <div className="radio">
                                {question.values.map((item: any) => (
                                  <div key={item.option}>
                                    <input
                                      type="radio"
                                      id={`${item.response}_${question._id}`}
                                      name={`radio_${question._id}`}
                                      value={item.response}
                                      checked={
                                        getResponseByQuestionId(
                                          question._id
                                        ) === item.response
                                      }
                                      onChange={(e) =>
                                        updateResponse(
                                          question._id,
                                          e.target.value,
                                          question.category
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${item.response}_${question._id}`}
                                    >
                                      {item.response}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              question.type === "checkbox" && (
                                <div className="checkbox">
                                  {question.values.map((item: any) => (
                                    <div key={item.option}>
                                      <input
                                        type="checkbox"
                                        name={item.response}
                                        value={item.response}
                                        checked={
                                          Array.isArray(
                                            getResponseByQuestionId(
                                              question._id
                                            ) as string[]
                                          ) &&
                                          (
                                            getResponseByQuestionId(
                                              question._id
                                            ) as string[]
                                          ).includes(item.response)
                                        }
                                        onChange={(e) => {
                                          const currentResponses =
                                            (getResponseByQuestionId(
                                              question._id
                                            ) as string[]) || [];
                                          const newResponse =
                                            currentResponses.includes(
                                              item.response
                                            )
                                              ? currentResponses.filter(
                                                  (r) => r !== item.response
                                                )
                                              : [
                                                  ...currentResponses,
                                                  item.response,
                                                ];
                                          updateResponse(
                                            question._id,
                                            newResponse,
                                            question.category
                                          );
                                        }}
                                      />
                                      <label htmlFor={item.response}>
                                        {item.response}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </td>
                          <td style={{ width: "30%" }}>
                            <input
                              type="text"
                              name="remarks"
                              value={
                                responses.find(
                                  (res) => res.questionid === question._id
                                )?.remarks || ""
                              }
                              onChange={(e) =>
                                updateRemarks(
                                  question._id,
                                  e.target.value,
                                  question.category
                                )
                              }
                              placeholder="Enter your remarks here"
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}

      {newAssessmentVar === false &&
        Object.keys(groupedByLatestCategory).length > 0 &&
        Object.keys(groupedByLatestCategory).map((set, index) => (
          <div key={set}>
            <div
              className="questionBtn"
              onClick={() => handleOpenClose(set)}
              //style={{ textAlign: "left" }}
            >
              <p>{set}</p>
              {isOpen[set] ? (
                <i className="fa-solid fa-chevron-down"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )}
            </div>
            {isOpen[set] && (
              <div>
                <table className="questionTable" style={{}}>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>Questions</td>
                      <td className="responseIsComplusory">Response</td>
                      <td>Remarks</td>
                    </tr>
                  </thead>
                  <tbody>
                    {newAssessmentVar === false &&
                      groupedByLatestCategory[set].map(
                        (question: any, index: any) => (
                          <tr key={question.questionid} className="summaryRow">
                            <td style={{ width: "3%" }}>{index + 1}</td>
                            <td style={{ width: "47%" }}>
                              {question.description}
                            </td>
                            <td style={{ width: "30%" }}>
                              {question.response[0]}
                            </td>
                            <td style={{ width: "20%" }}>{question.remarks}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}

      {newAssessmentVar === true && (
        <div
          className="saveBtnContainer"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div className="savebtn" onClick={sendResponses}>
            Save
          </div>
        </div>
      )}
    </div>
  );
};

export default Question;
