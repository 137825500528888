import { Navigate, Outlet } from "react-router-dom";
import { permissions } from "../../roles";

const ProtectedRoute = ({ requiredPermission }: any) => {
  const role = atob(sessionStorage.getItem("key") || "");
  if (role === "NA") {
    return <Navigate to="/Unauthorised" />;
  }

  if (!permissions[role?.toUpperCase()]?.includes(requiredPermission)) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
