import{createSlice, PayloadAction}from '@reduxjs/toolkit'
interface Project{
    proj_id: string;
    proj_name: string;
    isListed: boolean
}
interface ProjectState{
    projects:Project[];
}
const initialState: ProjectState ={
    projects: [],
};
const projectSlice = createSlice({
    name:'projects',
    initialState,
    reducers:{
        setProjects(state,action: PayloadAction<Project[]>) {
            state.projects = action.payload;
        },
        removeProject(state,action: PayloadAction<string>){
            state.projects = state.projects.map(project =>
               project.proj_id === action.payload ? {...project,isListed: false}: project 
            );
        },
    },
});

export const {setProjects, removeProject} = projectSlice.actions;
export default projectSlice.reducer;