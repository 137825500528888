import { Link, NavLink } from "react-router-dom";
import "./sidebar.scss";
import { permissions } from "../../../roles";

const Sidebar = () => {
  const role = atob(sessionStorage.getItem("key") || "");
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.assign(`${process.env.REACT_APP_COGNITOSSO_LOGOUT_URL}`);
  };
  return (
    <nav>
      <ul>
        <li>
          <Link to="">
            <div>
              <img
                className="sidebar_img"
                src={require("../../../images/avatar.png")}
                alt=""
              />
              <span>{`${sessionStorage.getItem(
                "PBT_FIRSTNAME"
              )} ${sessionStorage.getItem("PBT_LASTNAME")}`}</span>
            </div>
          </Link>
        </li>

        {permissions[role?.toUpperCase()]?.includes("dashboard") && (
          <li>
            <NavLink
              to="/dashboard"
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "gray" }
                  : { backgroundColor: "" };
              }}
            >
              <div>
                <img
                  className="sidebar_img"
                  src={require("../../../images/dashboard.png")}
                  alt=""
                />
                <span>Dashboard</span>
              </div>
            </NavLink>
          </li>
        )}
        {permissions[role?.toUpperCase()]?.includes("question") && (
          <li>
            <NavLink
              to="/create-your-question"
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "gray" }
                  : { backgroundColor: "" };
              }}
            >
              <div>
                <img
                  className="sidebar_img"
                  src={require("../../../images/create.png")}
                  alt=""
                />
                <span>Create Your Questions</span>
              </div>
            </NavLink>
          </li>
        )}
        {permissions[role?.toUpperCase()]?.includes("leadership") && (
          <li>
            <NavLink
              to="/leadership-report"
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "gray" }
                  : { backgroundColor: "" };
              }}
            >
              <div>
                <img
                  className="sidebar_img"
                  src={require("../../../images/leadership.png")}
                  alt=""
                />
                <span>Leadership Report</span>
              </div>
            </NavLink>
          </li>
        )}
        {permissions[role?.toUpperCase()]?.includes("access") && (
          <li>
            <NavLink
              to="/access-management"
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "gray" }
                  : { backgroundColor: "" };
              }}
            >
              <div>
                <img
                  className="sidebar_img"
                  src={require("../../../images/access.png")}
                  alt=""
                />
                <span>Access Management</span>
              </div>
            </NavLink>
          </li>
        )}
        <li className="logout" onClick={handleLogout}>
          <a style={{ cursor: "pointer" }}>
            <div>
              <img
                className="sidebar_img"
                src={require("../../../images/logout.png")}
                alt=""
              />
              <span>Logout</span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
