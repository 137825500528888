import { createSlice, PayloadAction  } from "@reduxjs/toolkit";
 
interface Finding {
    srlNo: string;
    observations: string;
    type: string;
    assignedBy: string;
    assignedTo: string;
    status: string;
    comments: string;
    created_date: string;
  }
  interface ObservationState{
    Findings: Finding[]
  }
  
  const initialState:ObservationState={
    Findings:[]
  }
export const findingVariableSlice = createSlice({
    name: "findingVariable",
    initialState,
    reducers:{
        addingfindingVariable: (state, action: PayloadAction<Finding[]>) =>{
            state.Findings = action.payload
        }
}
 
})
 
export default findingVariableSlice.reducer
export const {addingfindingVariable} = findingVariableSlice.actions;