import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { updateNewAssessmentVariable } from "../../../redux/slices/globalVariableSlice";
import Loading from "../../layout/loader/Loading";
import Navbar from "../../layout/header/Header";
import SearchInputBox from "../../layout/search/SearchInputBox";
import Pagination from "../../layout/pagination/Pagination";
import { elements } from "chart.js";
import axiosInstance from "../../../httpInterceptor";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // const CACHE_EXP_TIME = 10000000;
  // const [page, setPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState();
  // const [pageSize] = useState(16);
  // const [skipSize] = useState(0);

  const [loading, setLoading] = useState(true);
  const [compassData, setCompassData] = useState<any>(null);
  const [projectData, setProjectData] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [query, setQuery] = useState({
    projectid: "",
    projectname: "",
    duname: "",
    account: "",
    project_start_date: "",
    project_health: "",
  });
  const [filteredproject, setFilteredProject] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(5);

  let url;

  const handlequerychange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFilteredProject(projectData);
  }, [projectData]);

  useEffect(() => {
    let filtered = projectData;
    if (query.projectid) {
      filtered = filtered.filter((item: any) =>
        item.projectNumber
          .toLowerCase()
          .startsWith(query.projectid.toLowerCase())
      );
    }

    if (query.projectname) {
      filtered = filtered.filter((item: any) =>
        item.projectName
          .toLowerCase()
          .startsWith(query.projectname.toLowerCase())
      );
    }
    if (query.duname) {
      filtered = filtered.filter((item: any) =>
        item.u_delivery_unit
          .toLowerCase()
          .startsWith(query.duname.toLowerCase())
      );
    }
    if (query.account) {
      filtered = filtered.filter((item: any) =>
        item.accountName?.toLowerCase().startsWith(query.account.toLowerCase())
      );
    }
    if (query.project_start_date) {
      filtered = filtered.filter((item: any) =>
        item.start_date
          .toLowerCase()
          .startsWith(query.project_start_date.toLowerCase())
      );
    }
    if (query.project_health) {
      filtered = filtered.filter((item: any) =>
        item.health.toLowerCase().startsWith(query.project_health.toLowerCase())
      );
    }
    setFilteredProject(filtered);
    setCurrentPage(1);
  }, [query, projectData]);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredproject.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const fetchProjectsData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
      );

      if (data.length === 0) {
        url = `${process.env.REACT_APP_COMPASS_BOOMI_URL}`;
      } else {
        url = `${process.env.REACT_APP_COMPASS_BOOMI_URL}?Projectid=${data[0].projectid}`;
      }
      for (let i = 1; i < data.length; i++) {
        url = url + `&Projectid=${data[i].projectid}`;
      }

      const compassData = await axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_COMPASS_X_API_KEY}`,
        },
      });

      const response = await Promise.all(
        compassData.data.result.map(async (project: any) => {
          const projectStatus = await axiosInstance.get(
            `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/latest-proj-status/${project.projectNumber}`
          );

          return {
            ...project,
            health: !projectStatus.data.message ? projectStatus.data : "",
          };
        })
      );

      setProjectData(response);
    } catch (error: any) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectsData();
  }, [compassData]);

  const handleNewAssessmentClick = () => {
    navigate("/dashboard/create-project");
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="error">
        <h3>Something went wrong!</h3>
      </div>
    );
  }

  return (
    <div className="dashboard_container">
      <Navbar />
      <div className="app-pageSize">
        <div className="app-pageHeader">Project List</div>
        {projectData.length > 0 ? (
          <div className="projectlistTableContainer">
            <table className="tstylee">
              <thead>
                <tr>
                  <td>Project Id</td>
                  <td>Project Name</td>
                  <td>DU Name</td>
                  <td>Account</td>
                  <td>Project Start Date</td>
                  <td>Project Health</td>
                </tr>
              </thead>
              <tbody>
                {/* table row for the search input boxes */}
                <tr>
                  <td className="inputContainer1">
                    <input
                      type="text"
                      name="projectid"
                      onChange={handlequerychange}
                    />
                    {query.projectid.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                  <td className="inputContainer2">
                    <input
                      type="text"
                      name="projectname"
                      onChange={handlequerychange}
                    />
                    {query.projectname.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                  <td className="inputContainer3">
                    <input
                      type="text"
                      name="duname"
                      onChange={handlequerychange}
                    />
                    {query.duname.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                  <td className="inputContainer4">
                    <input
                      type="text"
                      name="account"
                      onChange={handlequerychange}
                    />
                    {query.account.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                  <td className="inputContainer5">
                    <input
                      type="text"
                      name="project_start_date"
                      onChange={handlequerychange}
                    />
                    {query.project_start_date.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                  <td className="inputContainer6">
                    <input
                      type="text"
                      name="project_health"
                      onChange={handlequerychange}
                    />
                    {query.project_health.length === 0 && (
                      <i className="fa-solid fa-magnifying-glass"></i>
                    )}
                  </td>
                </tr>
                {currentProjects.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="nodata">
                      No Record Found
                    </td>
                  </tr>
                ) : (
                  currentProjects.map((project: any) => (
                    <tr key={project.projectNumber}>
                      <td>
                        <Link
                          to={`/dashboard/re-assessment/${project.projectNumber}`}
                          onClick={() => {
                            dispatch(updateNewAssessmentVariable(false));
                          }}
                        >
                          {project.projectNumber}
                        </Link>
                      </td>
                      <td>{project.projectName}</td>
                      <td>{project.u_delivery_unit}</td>
                      <td>{project.accountName}</td>
                      <td>{project.start_date.split(" ")[0]}</td>
                      <td>
                        {project.health === "red" ? (
                          <div className="red"></div>
                        ) : project.health === "green" ? (
                          <div className="green"></div>
                        ) : project.health === "amber" ? (
                          <div className="amber"></div>
                        ) : (
                          <div>N/A</div>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {/* pagination component inside layout folder */}
            {filteredproject.length > 5 ? (
              <Pagination
                projectsPerPage={projectsPerPage}
                totalProjects={filteredproject.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : null}
            {/* new assessment button implementation */}
            <div className="newAssessment">
              <button onClick={handleNewAssessmentClick}>
                New Assessment +
              </button>
            </div>
          </div>
        ) : (
          // new assessment button implementation when there is no project assessment
          <div>
            No Assessment Found! Take new Assessment.
            <div className="newAssessment">
              <button onClick={handleNewAssessmentClick}>
                New Assessment +
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
