import { jwtDecode } from "jwt-decode";

export const db = {
  DU: [
    "BF",
    "EY",
    "TH",
    "RE",
    "MD",
    "LF",
    "NS",
    "MN",
    "HH",
    "UA",
    "HE",
    "PB",
    "FT",
  ],
  PU: [
    "CIS",
    "CSU",
    "DATA",
    "DSA",
    "IIOT",
    "LI",
    "NXT",
    "OR",
    "SAP",
    "SFDC",
    "TT",
  ],
  CATEGORY: [
    "Contract Management",
    "Collaboration",
    "Compliance",
    "Customer Engagement",
    "Estimation",
    "Execution(Process)",
    "Execution(Technology)",
    "Infrastructure",
    "Integration",
    "Licenses",
    "Project Management",
    "Requirement",
    "Resourcing",
    "Risk Management",
    "Scope of Work",
    "Test Management",
    "Third Party",
    "User Acceptance",
  ],
  statusOptions: [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Close", label: "Close" },
  ],
  typeOptions: [
    { value: "Note", label: "Note" },
    { value: "Risk", label: "Risk" },
    { value: "Suggestion", label: "Suggestion" },
    { value: "Action", label: "Action" },
  ],
  projectListData: [
    {
      id: 1,
      proj_id: "343434",
      proj_name: "AEG-TNM-Maintainance",
      type: "RA Small",
      pmid: "542617",
      du: "MN",
      pu: "CSU",
      account: "Travellers",
      proj_start_date: "2024-05-03",
      proj_end_date: "2024-05-30",
      reviewer: "Naziya",
    },
    {
      id: 2,
      proj_id: "2345-08",
      proj_name: "Interactive and Low code",
      type: "RA Small",
      pmid: "542617",
      du: "HH",
      pu: "CSU",
      account: "Fair Fox",
      proj_start_date: "2024-05-05",
      proj_end_date: "2024-05-30",
      reviewer: "Arijit",
    },
  ],
  userListData: [
    {
      id: "1",
      firstName: "Rupesh",
      lastName: "Kumar",
      emailId: "rupesh.kumar2@ltimindtree.com",
      "contact-no": "9546042037",
      role: "admin",
    },
    {
      id: "2",
      firstName: "Aditya",
      lastName: "Dethe",
      emailId: "aditya.dethe@ltimindtree.com",
      "contact-no": "9546042032",
      role: "",
    },
    {
      id: "3",
      firstName: "Ajay",
      lastName: "Soni",
      emailId: "ajay.soni@ltimindtree.com",
      "contact-no": "9546042033",
      role: "",
    },
    {
      id: "4",
      firstName: "Rajan",
      lastName: "Dutta",
      emailId: "rajan.dutta@ltimindtree.com",
      "contact-no": "9546042035",
      role: "",
    },
  ],
  questionsList: [
    {
      id: "q1",
      description: "Did you complete the required training?",
      type: "dropdown",
      values: [
        {
          option: "1",
          response: "Completed",
        },
        {
          option: "2",
          response: "Not-completed",
        },
        {
          option: "3",
          response: "Requested",
        },
      ],
    },
    {
      id: "q2",
      description: "Are you satisfied with project timeline?",
      type: "text",
      values: [{ option: "1", response: "" }],
    },
    {
      id: "q3",
      description: "Have you reviewed the project documentation",
      type: "radio",
      values: [
        { option: "1", response: "Yes" },
        { option: "2", response: "No" },
      ],
    },
  ],
  assessmentDetails: [
    {
      id: "a1",
      projectid: "1853-01",
      observations: [
        {
          srlno: "1",
          description: "aaaaa",
          type: "ssss",
          assignedby: "aaaa@cty.com",
          assignedto: "ssss@rty.com",
          status: "open",
          comments: "sasasasas",
          created_by: "aaaa@bb.com",
          created_date: "2024-01-23",
          mofified_date: "",
          modified_by: "",
        },
        {
          srlno: "2",
          description: "aaaaa",
          type: "ssss",
          assignedby: "aaaa@cty.com",
          assignedto: "ssss@rty.com",
          status: "open",
          comments: "sasasasas",
          created_by: "aaaa@bb.com",
          created_date: "2024-01-23",
          mofified_date: "",
          modified_by: "",
        },
      ],
      assessmentdetails: [
        {
          assessmentid: "AS01",
          description: "Initial Assessment for Project kickstart",
          assmntdate: "2024-04-20",
          projectstatus: "green",
          reasonforchange: " ",
          qnadetails: [
            {
              questionid: "Q1",
              response: [{ r1: "Yes" }],
            },
            {
              questionid: "Q2",
              response: [
                {
                  r1: "10% P5 associates, 60% P3 associates , 30% P1 associates",
                },
                {
                  r2: "1 Deleivery Manage at Offshore and 1 Tech Lead at Onshore",
                },
              ],
            },
          ],
          created_by: "Abc@xyz.com",
          created_date: "2024-05-06",
          modified_by: "",
          modified_date: "",
        },
        {
          assessmentid: "AS02",
          description: "Project readyness",
          assmntdate: "2024-04-20",
          projectstatus: "amber",
          reasonforchange: " ",
          qnadetails: [
            {
              questionid: "Q1",
              response: [{ r1: "Yes" }],
            },
            {
              questionid: "Q2",
              response: [
                {
                  r1: "10% P5 associates, 60% P3 associates , 30% P1 associates",
                },
                {
                  r2: "1 Deleivery Manage at Offshore and 1 Tech Lead at Onshore",
                },
              ],
            },
          ],
          created_by: "Abc@xyz.com",
          created_date: "2024-05-06",
          modified_by: "",
          modified_date: "",
        },
      ],
    },
    {
      id: "a2",
      projectid: "2345-08",
      observations: [
        {
          srlno: "1",
          description: "aaaaa",
          type: "ssss",
          assignedby: "aaaa@cty.com",
          assignedto: "ssss@rty.com",
          status: "open",
          comments: "sasasasas",
          created_by: "aaaa@bb.com",
          created_date: "2024-01-23",
          mofified_date: "",
          modified_by: "",
        },
        {
          srlno: "2",
          description: "aaaaa",
          type: "ssss",
          assignedby: "aaaa@cty.com",
          assignedto: "ssss@rty.com",
          status: "open",
          comments: "sasasasas",
          created_by: "aaaa@bb.com",
          created_date: "2024-01-23",
          mofified_date: "",
          modified_by: "",
        },
      ],
      assessmentdetails: [
        {
          assessmentid: "AS01",
          description: "Initial Assessment for Project kickstart",
          assmntdate: "2024-04-20",
          projectstatus: "red",
          reasonforchange: " ",
          qnadetails: [
            {
              questionid: "Q1",
              response: [{ r1: "Yes" }],
            },
            {
              questionid: "Q2",
              response: [
                {
                  r1: "10% P5 associates, 60% P3 associates , 30% P1 associates",
                },
                {
                  r2: "1 Deleivery Manage at Offshore and 1 Tech Lead at Onshore",
                },
              ],
            },
          ],
          created_by: "Abc@xyz.com",
          created_date: "2024-05-06",
          modified_by: "",
          modified_date: "",
        },
        {
          assessmentid: "AS02",
          description: "Project readyness",
          assmntdate: "2024-04-20",
          projectstatus: "amber",
          reasonforchange: " ",
          qnadetails: [
            {
              questionid: "Q1",
              response: [{ r1: "Yes" }],
            },
            {
              questionid: "Q2",
              response: [
                {
                  r1: "10% P5 associates, 60% P3 associates , 30% P1 associates",
                },
                {
                  r2: "1 Deleivery Manage at Offshore and 1 Tech Lead at Onshore",
                },
              ],
            },
          ],
          created_by: "Abc@xyz.com",
          created_date: "2024-05-06",
          modified_by: "",
          modified_date: "",
        },
      ],
    },
  ],
  nameList: [
    {
      name: "Aditya Dethe",
      email: "aditya.dethe@ltimindtree.com",
      psNo: 1749180,
      role: "Reviewer",
      jobtitle: "Software Engineer",
      modifiedBy: "Aditya",
    },
    {
      name: "Rupesh Kumar",
      email: "rupesh.kumar@ltimindtree.com",
      psNo: 879680,
      role: "Admin",
      jobtitle: "Software Engineer",
      modifiedBy: "Rupesh",
    },
    {
      name: "Karuna Bisen",
      email: "karuna.bisen@ltimindtree.com",
      psNo: 38492739,
      role: "Reviewer",
      jobtitle: "Cloud Engineer",
      modifiedBy: "Karuna",
    },
    {
      name: "pranita pol",
      email: "pranita.pol@ltimindtree.com",
      psNo: 3298792,
      role: "Project Manager",
      jobtitle: "Java Developer",
      modifiedBy: "Pranita",
    },
  ],
  role: ["Admin", "Reviewer", "Project Manager", "NA"],
};

export function generateUniqueId(collection) {
  if (collection.length === 0) return 1;
  const ids = collection.map((item) => item.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

export function convertToIso(date) {
  if (date) {
    const ddmmyyyy = date.split(" ")[0];
    const dateParts = ddmmyyyy.split("-");

    const year = dateParts[2];
    const month = dateParts[0];
    const day = dateParts[1];

    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
}

export function checkTokenExpiry() {
  if (sessionStorage.getItem("PBT_ID_TOKEN")) {
    const token = sessionStorage.getItem("PBT_ID_TOKEN");
    const decode = jwtDecode(token);

    const currentTime = Date.now() / 1000;

    const tokenExpiryTime = decode.exp;

    if (tokenExpiryTime - currentTime < 5 * 60) {
      const refresh_token = sessionStorage.getItem("PBT_REFRESH_TOKEN");
      fetch(
        `https://ltim-de-user-pool.auth.us-east-1.amazoncognito.com/oauth2/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `grant_type=refresh_token&refresh_token=${refresh_token}&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
        }
      )
        .then((response) => response.json())
        .then((res) => {
          localStorage.setItem("PBT_ACCESS_TOKEN", res.access_token);
          localStorage.setItem("PBT_ID_TOKEN", res.id_token);
        })
        .catch((err) => console.log(err));
    }
  }
}
