export const roles = {
  ADMIN: "ADMIN",
  REVIEWER: "REVIEWER",
  AUDITOR: "AUDITOR",
  LEADERSHIP: "LEADERSHIP",
  "PROJECT MANAGER": "PROJECT MANAGER",
};

export const permissions = {
  [roles.ADMIN]: ["dashboard", "question", "leadership", "access"],
  [roles.REVIEWER]: ["dashboard"],
  [roles.AUDITOR]: ["dashboard"],
  [roles.LEADERSHIP]: ["dashboard", "leadership"],
  [roles["PROJECT MANAGER"]]: ["dashboard"],
};
